#slider-2 {
  position: absolute;
  right: 0;
  top: 50px;
  width: 150px;
  height: 250px;
  z-index: 9999999;
}

.slides {
  list-style: none;
  margin: 0;
  padding: 0;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
}

/*! Flickity v1.0.2
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.gallery-cell {
  width: 100%;
}

.btn-next {
  background: none;
  border: none;
  position: absolute;
  right: 0;
  bottom: 20px;
  width: 60px;
  height: 20px;
  padding: 0;

  &:focus {
    outline: none;
  }

  svg {
    display: block;
    width: 60px;
    height: 20px;

    #arrow-line {
      transition: all 0.3s ease-out;
      transform-origin: right bottom;
    }
  }

  &:hover {
    svg {
      #arrow-line {
        transform: scaleX(1.5);
      }
    }
  }
}
