.box404 {
	position: relative;
	margin-top: 86px;

	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 1rem;
}

@-webkit-keyframes kenburns-top {
	0% {
		-webkit-transform: scale(1) translateY(0);
		transform: scale(1) translateY(0);
		-webkit-transform-origin: 50% 16%;
		transform-origin: 50% 16%;
	}
	100% {
		-webkit-transform: scale(1.25) translateY(-15px);
		transform: scale(1.25) translateY(-15px);
		-webkit-transform-origin: top;
		transform-origin: top;
	}
}

@keyframes kenburns-top {
	0% {
		-webkit-transform: scale(0.9) translateY(0);
		transform: scale(0.9) translateY(0);
		-webkit-transform-origin: 50% 16%;
		transform-origin: 50% 16%;
	}
	100% {
		-webkit-transform: scale(1) translateY(0);
		transform: scale(1) translateY(0);
		-webkit-transform-origin: top;
		transform-origin: top;
	}
}

.hero404 {
	width: 100%;
	max-width: 1077px;
	margin: 100px 0;

	-webkit-animation: kenburns-top 2.5s ease-out both;
	animation: kenburns-top 2.5s ease-out both;
}

.content404 {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	& > span {
		width: 100%;
		max-width: 800px;

		font-size: 42px;
		font-weight: 700;
		line-height: 49px;
		text-align: center;
		color: #1d1d1b;

		margin-bottom: 40px;
	}
}

.button404 {
	width: max-content;
	padding: 16px 30px;
	background: #fcb53b;
	box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.05);

	font-size: 16px;
	font-weight: 400;
	line-height: 19px;
	color: #1d1d1b;
}

@media (max-width: 900px) {
	.content404 {
		position: absolute;
		// top: 110px;
		// left: 50%;
		// transform: translate(-50%, 0);

		& > span {
			font-size: 21px;
			font-weight: 700;
			line-height: 23px;
		}
	}
}

@media (max-width: 575px) {
	.box404 {
		margin-top: 0;
		height: 300px;
	}

	.hero404 {
		width: 100%;
		max-width: 1077px;
		margin: 50px 0 100px 0;
	}
}
