/* react-image-gallery styles */

.image-gallery-thumbnails-container {
	width: 500px;
	padding: 0;
	margin-top: 8px;
}

button.image-gallery-thumbnail {
	width: 115px;
	height: 115px;
	position: relative;
	margin: 0;
	margin-right: 12px;
	border: unset !important;
}

button.image-gallery-thumbnail::after {
	position: absolute;
	width: 100%;
	height: 100%;
	content: "";
	background-color: #fff;
	top: 0;
	left: 0;
	opacity: 0.4;
	transition: opacity 0.2s ease-in-out;
}

button.image-gallery-thumbnail:last-child {
	margin-right: 0;
}

.image-gallery-thumbnail + .image-gallery-thumbnail {
	margin-left: 0;
}

button.image-gallery-thumbnail:hover {
	border: unset !important;
}

button.image-gallery-thumbnail:hover::after {
	opacity: 0;
}

button.image-gallery-thumbnail.active {
	border: unset !important;
}

button.image-gallery-thumbnail.active::after {
	opacity: 0;
}

/* .originalImageClass {
	border: 2px solid red;
	max-width: 100%;
}

.originalImageClass .image-gallery-image {
	object-fit: cover;
}

.image-gallery-content.bottom.fullscreen .image-gallery-image {
	object-fit: contain;
} */
