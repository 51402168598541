@import url("https://fonts.googleapis.com/css2?family=Waiting+for+the+Sunrise&display=swap");

* {
	outline: none !important;
	position: relative;
}

html {
	scroll-behavior: smooth;
}

.page-holder {
	overflow: hidden !important;
	position: relative;
}

a:hover {
	text-decoration: none;
}

.btn:focus {
	outline: 0;
}

button:focus {
	outline: 0;
}

img {
	max-width: 100%;
	height: auto;
}

.mobile-only {
	display: none;
}

.accordion-onbtn-open {
	display: none;

	&.show {
		display: block;
	}
}

#shopByBtn {
	i {
		font-size: 18px;
		margin-left: 10px;
	}
}

*::-webkit-media-controls-panel {
	display: none !important;
	-webkit-appearance: none;
}

*::--webkit-media-controls-play-button {
	display: none !important;
	-webkit-appearance: none;
}

*::-webkit-media-controls-start-playback-button {
	display: none !important;
	-webkit-appearance: none;
}

//tabs animation

#arrow {
	-webkit-animation: arrow-jump 1s infinite ease-in-out; /* Safari 4+ */
	-moz-animation: arrow-jump 1s infinite ease-in-out; /* Fx 5+ */
	-o-animation: arrow-jump 1s infinite ease-in-out; /* Opera 12+ */
	animation: arrow-jump 1s infinite ease-in-out; /* IE 10+, Fx 29+ */
}

@keyframes arrow-jump {
	0% {
		opacity: 1;
	}
	25% {
		opacity: 1;
		-webkit-transform: translateX(4px);
		-moz-transform: translateX(4px);
		-transform: translateX(4px);
		transform: translateX(4px);
	}
	50% {
		opacity: 1;
		-webkit-transform: translateX(8px);
		-moz-transform: translateX(8px);
		-transform: translateY(6px);
		transform: translateX(8px);
	}
	75% {
		opacity: 1;
		-webkit-transform: translateX(4px);
		-moz-transform: translateX(4px);
		-transform: translateX(4px);
		transform: translateX(4px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateX(0px);
		-moz-transform: translateX(0px);
		-transform: translateX(0px);
		transform: translateX(0px);
	}
}

.input-group input::placeholder {
	color: #fff;
	opacity: 1; /* Firefox */
	font-weight: 300;
}

.input-group input:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #fff;
}

.input-group input::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #fff;
}

.input-group textarea::placeholder {
	color: #fff;
	opacity: 1; /* Firefox */
}

.input-group textarea:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #fff;
}

.input-group textarea::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #fff;
}
//
.fadeToYellow {
	color: #fbaf2e;
	-webkit-transition: all 2s ease;
	transition: all 2s ease;
}
.fadeToWhite {
	color: #ffffff;
	-webkit-transition: all 2s ease;
	transition: all 2s ease;
}
.fadeToBlack {
	color: #000;
	transition: all 2s ease;
}
// Slick Slider

.slick-dots {
	position: absolute;
	display: flex;
	align-items: center;
	list-style: none;
	justify-content: center;
	width: 100%;
	margin-top: 40px;
	bottom: -75px;

	li {
		margin-right: 20px;

		button {
			border: 0;
			font-size: 0;
			width: 11px;
			height: 11px;
			background: #cccccb;
			border-radius: 50%;
			&::before {
				content: "";
			}
		}

		&.slick-active {
			button {
				background: #9d9b9b;
			}
		}

		&:nth-last-child(1) {
			margin-right: 0;
		}
	}
}

//

footer {
	background-color: #1c1c1c;
	padding-bottom: 90px;

	.footer-menu {
		ul {
			display: flex;
			list-style: none;
			align-items: center;
			justify-content: center;

			li {
				margin-right: 40px;

				a {
					color: #6c6c6c;
					font-size: 20px;
					font-weight: 400;
					letter-spacing: 1.2px;
					transition: all 0.3s;

					&:hover {
						color: #fff;
						font-weight: 400;
						transition: all 0.3s;
					}
				}

				&:nth-last-child(1) {
					margin-right: 0;
				}
			}
		}

		.footer-nav-hr-line {
			width: 100%;
			height: 1px;
			display: block;
			background-color: #6c6a66;
			margin: 30px 0;
		}
	}

	.footer-info {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.leftside {
			.first {
				color: #6c6c6c;
				font-size: 17px;
				font-weight: 500;
				letter-spacing: 1.2px;

				&:hover {
					text-decoration: underline;
					color: #fff;
				}
			}

			.email {
				color: #6c6c6c;
				font-size: 17px;
				margin: 15px 0;
				display: block;
				font-weight: 500;
				letter-spacing: 1.2px;

				&:hover {
					text-decoration: underline;
					color: #fff;
				}
			}

			.copyright {
				font-size: 17px;
				color: #6c6c6c;
				text-transform: uppercase;
				font-weight: 500;
				letter-spacing: 1.2px;
			}
		}

		.rightside {
			.social {
				display: flex;
				align-items: center;

				.follow-us {
					color: #6c6c6c;
					font-size: 17px;
					font-weight: 500;
					letter-spacing: 1.2px;
					margin: 0;
					margin-right: 25px;
				}

				.social-icons-holders {
					display: flex;
					align-items: center;

					a {
						min-height: 36px;
						max-height: 36px;
						min-width: 36px;
						max-width: 36px;
						border-radius: 50%;
						border: 2px solid #6c6c6c;
						display: flex;
						align-items: center;
						justify-content: center;
						color: #6c6c6c;
						padding: 16px;
						margin-right: 15px;

						i {
							font-size: 17px;
						}

						&:hover {
							border: 2px solid #fff;

							i {
								color: #fff;
							}
						}

						&:nth-last-child(1) {
							margin-right: 0;
						}
					}
				}
			}
		}
	}
}

header {
	position: absolute;
	width: 100%;
	z-index: 1;
	padding-top: 40px;

	.header-holder {
		padding-bottom: 20px;
		.mobile-holder-flex {
			display: flex;
			justify-content: space-between;
			align-items: baseline;
		}
	}
}

.homepage-holder {
	.section-one {
		margin-bottom: 120px;
		position: relative;
		min-height: 870px;
		overflow: hidden;

		&.pallets {
			margin-bottom: 0;
		}

		video {
			position: absolute;
			width: 100%;
			height: 870px;
			overflow: hidden;
			background-position: center top;
			object-fit: cover !important;
		}

		.section-background-image {
			background-image: url("/img/section-1-background-image.png");
			width: 100%;
			min-height: 870px;
			max-height: 870px;
			position: absolute;
			top: 0;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			display: block;
		}

		.section-background-image-overlay {
			width: 100%;
			min-height: 870px;
			max-height: 870px;
			position: absolute;
			top: 0;
			bottom: 0;
			background: #000;
			opacity: 0.5;
		}

		.text-holder {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			min-height: 870px;

			h1 {
				color: #fff;
				font-size: 69px;
				font-weight: 500;
				margin-bottom: 25px;
				letter-spacing: 2.2px;
			}

			h4 {
				color: #fff;
				font-size: 35px;
				font-weight: 400;
				margin-bottom: 50px;
				letter-spacing: 1.2px;
			}

			.hero-btn {
				color: #fff;
				background-color: #eda228;
				font-size: 25px;
				padding: 10px 25px;
				display: flex;
				justify-content: center;
				align-items: center;
				-webkit-transition: all 0.3s;
				-moz-transition: all 0.3s;
				transition: all 0.3s;
				font-weight: 400;
				min-width: 220px;
				margin: 0 auto;
				&:hover {
					background-color: #f4b959;
				}
			}
		}
	}

	.section-two {
		//background: url("/img/trucks.jpg") no-repeat center center;
		background-size: cover;
		margin-bottom: 0px;
		padding: 0;

		.container {
			width: 100%;
			padding: 120px 0;
			background-color: rgb(89, 162, 215, 0.75);
			max-width: 100%;
		}

		.titles-holder {
			text-align: center;
			margin-bottom: 60px;

			.section-title {
				font-size: 52px;
				color: #ffffff;
				text-align: center;
				margin-bottom: 15px;
				font-weight: 500;
				letter-spacing: 1.2px;
			}

			.section-subtitle {
				font-size: 28px;
				color: #ffffff;
				text-align: center;
				letter-spacing: 1.2px;
				font-weight: 400;
			}
		}

		.boxes-holder {
			.box {
				max-width: 416px;
				position: relative;
				overflow: hidden;

				.image-holder {
					img {
						transform: scale(1);
						-webkit-transform: scale(1);
						transition: all 0.4s linear;
						-webkit-transition: all 0.4s linear;
						-moz-transition: all 0.4s linear;
						-o-transition: all 0.4s linear;
					}
				}

				.text-holder {
					position: absolute;
					bottom: 20px;
					width: 100%;
					margin: 0 auto;
					text-align: center;

					p {
						color: #fff;
						font-size: 60px;
						font-weight: bold;
					}
				}

				.hover-text {
					display: none;
				}

				&:hover {
					.image-holder {
						img {
							transform: scale(1.03);
							-webkit-transform: scale(1.03);
						}
					}

					.hover-text {
						position: absolute;
						top: 0;
						bottom: 0;
						background: #eda22833;
						/* opacity: .7; */
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: center;

						.view-now {
							font-size: 35px;
							font-weight: 500;
							color: #fff;
							letter-spacing: 2.2px;
						}
					}
				}
			}

			.button-holder {
				margin-top: 0px;
				text-align: center;

				.section-btn {
					background-color: #eda228;
					color: #fff;
					font-size: 36px;
					padding: 10px 25px;
					display: flex;
					justify-content: center;
					align-items: center;
					-webkit-transition: all 0.3s;
					-moz-transition: all 0.3s;
					transition: all 0.3s;
					font-weight: 400;
					margin: 0 auto;
					display: inline-block;

					&:hover {
						background-color: #f4b959;
					}
				}
			}
		}
	}

	.section-three {
		background-color: #f7f8f8;
		padding-bottom: 70px;

		.titles-holder {
			padding-top: 100px;
			margin-bottom: 80px;
			text-align: center;

			.minititle {
				font-size: 20px;
				color: #6b6b6b;
				text-align: left;
				font-weight: 400;
				margin-bottom: 40px;
				letter-spacing: 2.2px;
			}

			.section-title {
				font-size: 52px;
				font-weight: 500;
				letter-spacing: 2.2px;
				color: #1c1c1c;
				text-align: left;
				max-width: 1000px;
				width: 100%;
			}
		}

		.tabs-holder {
			.nav-tabs {
				border-bottom: 1px solid #ffd79f;

				.nav-link {
					background: transparent;
					border: 0;
					min-width: 20%;
					font-family: "Roboto", sans-serif;

					.number {
						font-size: 22px;
						color: #fcb02aa3;
					}

					.name {
						font-size: 22px;
						color: #fcb02aa3;
						letter-spacing: 1.2px;
						margin-bottom: 20px;
					}

					&.active {
						.number {
							color: #fcb02a;
						}

						.name {
							color: #fcb02a;
						}

						&::after {
							width: 100%;
							left: 0;
							transition-delay: 0.3s;
						}
					}

					&::after {
						content: "";
						width: 0;
						position: absolute;
						background-color: #fcb02a;
						height: 6px;
						display: flex;
						bottom: -1px;
						transition-property: width;
						transition-duration: 0.3s;
						transition-timing-function: ease-out;
						right: 0;
					}
				}
			}

			.tab-content {
				padding-top: 80px;
				padding-bottom: 80px;

				.tab-pane {
					.tab-body {
						font-size: 25px;
						max-width: 1000px;
						font-weight: 400;
						color: #1c1c1c;
					}

					&.fade {
						transition: all 0.5s ease-in-out;
						transform: translateX(-50px);
						opacity: 0;

						&.show {
							transition: all 0.3s ease-in-out;
							transform: translateX(0rem);
							opacity: 1;
						}
					}
				}
			}
		}

		.button-holder {
			transition: transform 0.25s ease-in-out;
			min-height: 80px;
			//margin-top: 100px;
			max-width: 185px;
			min-width: 185px;

			.section-btn {
				background-color: #eda228;
				color: #fff;
				font-size: 25px;
				padding: 10px 25px;
				display: inline-flex;
				align-items: center;
				-webkit-transition: all 0.3s;
				-moz-transition: all 0.3s;
				transition: all 0.3s;
				font-weight: 400;
				max-width: 185px;
				min-width: 185px;
			}

			&:hover {
				.section-btn {
					background-color: #f4b959;

					.arrow-right {
						img {
							transform: translatex(10px);
							transition: transform 0.25s ease;
						}
					}
				}
			}
		}
	}

	.section-three-mobile {
		display: none;
	}

	.section-four {
		min-height: 871px;
		overflow: hidden;

		.section-background-image {
			// background-image: url("/img/section-4-background-image-newest.png");
			width: 100%;
			min-height: 1400px;
			max-height: 1400px;
			position: absolute;
			top: 0;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			display: block;
		}

		.text-holder {
			min-height: 871px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			text-align: left;

			h2 {
				color: #fff;
				font-size: 100px;
				font-weight: 500;
				text-transform: uppercase;
			}

			.hero-btn {
				color: #fff;
				background-color: #eda228;
				font-size: 25px;
				padding: 10px 25px;
				display: flex;
				justify-content: center;
				align-items: center;
				-webkit-transition: all 0.3s;
				-moz-transition: all 0.3s;
				transition: all 0.3s;
				font-weight: 400;
				max-width: 185px;
				min-width: 185px;
				margin: 0 auto;

				&:hover {
					background-color: #f4b959;
				}
			}
		}
	}

	.section-five {
		margin-bottom: 140px;

		.text-holder {
			text-align: center;

			.lead-text {
				font-size: 47px;
				color: #1c1c1c;
				font-weight: bold;
				letter-spacing: 2.2px;
			}

			.text {
				font-size: 20px;
				color: #6b6b6b;
				margin-top: 15px;
				letter-spacing: 2.2px;
				font-weight: 500;
			}
		}

		.s5-hr-line {
			width: 100%;
			height: 1px;
			display: block;
			background-color: #ebebea;
			margin: 80px 0;
		}
	}

	.section-six {
		margin: 80px 0;

		.secondCol {
			.text-holder {
				padding-left: 60px;
				padding-top: 130px;

				.section-title {
					font-size: 52px;
					color: #1c1c1c;
					text-align: left;
					max-width: 1000px;
					width: 100%;
					margin-bottom: 15px;
					letter-spacing: 2.2px;
					font-weight: 500;
				}

				.section-subtitle {
					font-size: 25px;
					font-weight: 400;
					color: #1c1c1c;
					line-height: 140%;
					text-align: justify;
				}
			}
		}
	}

	.section-seven {
		background-color: #1c1c1c;
		padding: 80px 0 70px 0;

		.content-holder {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.list-holder {
				.glove-title {
					color: #000;
					font-size: 110px;
					margin-bottom: 20px;

					.blacktowhite {
						color: #000;
					}

					span {
						color: #000;
					}
				}

				.list-item {
					.zero {
						color: #fbaf2e;
						font-family: "Roboto Condensed", sans-serif;
						font-size: 45px;
						font-weight: bold;
					}

					font-family: "Roboto Condensed", sans-serif;
					font-size: 45px;
					color: #fff;
					font-weight: bold;
					letter-spacing: 2.2px;
				}
			}
		}
	}

	.section-nine {
		padding: 70px 0;

		.titles-holder {
			text-align: center;

			.section-title {
				color: #1c1c1c;
				font-size: 52px;
				margin-bottom: 15px;
				letter-spacing: 2.2px;
				font-weight: 500;
			}

			.section-subtitle {
				color: #1c1c1c;
				font-size: 28px;
				max-width: 800px;
				font-weight: 400;
				margin: 0 auto;
				letter-spacing: 1.2px;
				margin-bottom: 50px;
				text-align: center;
			}
		}

		.button-holder {
			text-align: center;

			.section-btn {
				background-color: #eda228;
				color: #fff;
				font-size: 25px;
				padding: 10px 25px;
				display: inline-flex;
				align-items: center;
				-webkit-transition: all 0.3s;
				-moz-transition: all 0.3s;
				transition: all 0.3s;
				font-weight: 400;
				max-width: 185px;
				min-width: 185px;

				&:hover {
					background: #f4b959;
				}
			}
		}
	}

	// .section-ten {
	//   background-color: #1c1c1c;
	//   padding: 80px 0;

	//   .image-holder {
	//     text-align: center;
	//     margin-bottom: 50px;
	//   }

	//   .text-holder {
	//     text-align: center;
	//     margin-bottom: 50px;

	//     p {
	//       font-size: 28px;
	//       font-weight: 300;
	//       color: #fff;
	//       max-width: 770px;
	//       margin: 0 auto;
	//       width: 100%;
	//       line-height: 120%;
	//     }
	//   }

	//   .subscribe-holder {
	//     max-width: 380px;
	//     margin: 0 auto;

	//     .input-group {
	//       border-bottom: 1px solid #6b6b6b;
	//       padding-bottom: 5px;

	//       input {
	//         border-left: 0;
	//         border-radius: 0;
	//         background-color: transparent;
	//         padding-left: 10px;
	//         border: 0;
	//         padding-bottom: 0;
	//         color: #fff;

	//         &::placeholder {
	//           color: #6b6b6b;
	//         }

	//         &:focus {
	//           color: #fff;
	//           border: 0;
	//           background: transparent;
	//           box-shadow: none;
	//         }
	//       }

	//       .input-group-append {
	//         border-radius: 0;
	//         border: 0;
	//         border-right: 0;
	//         padding: 6px 17px;

	//         img {
	//           max-height: 16px;
	//         }
	//       }

	//       .count-me-in {
	//         border-radius: 0;
	//         border: 0;
	//         border-right: 0;
	//         background: transparent;
	//         color: #fff;

	//         img {
	//           max-width: 30px;
	//           position: relative;
	//           top: -3px;
	//           right: 17px;
	//         }
	//       }
	//     }
	//   }
	// }
}

.about-holder {
	header {
		background: #fff;
		position: relative;

		// .header-holder {
		// .mobile-holder-flex {
		// .header-navigation-menu {
		//   .navbar-nav {
		//     .nav-link {
		//       color: #1c1c1c;

		//       &:hover {
		//         color: #eda228;
		//       }

		//       &.join-us-nav-link {
		//         color: #fff;
		//       }
		//     }
		//   }
		// }
		// }
		// }
	}

	.section-one {
		margin-bottom: 80px;
		position: relative;
		min-height: 600px;

		.section-background-image {
			background-image: url("/img/aboutus/hero-image-gradient.png");
			width: 100%;
			min-height: 600px;
			max-height: 600px;
			position: absolute;
			top: 0;
			background-size: cover;
			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-position: center;
			display: block;
		}

		.text-holder {
			min-height: 600px;
			max-width: 750px;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;

			.titles-holder {
				padding: 100px 0;

				h1 {
					color: #1c1c1c;
					font-size: 52px;
					font-weight: 500;
					margin-bottom: 10px;
					letter-spacing: 2.2px;
				}

				span {
					color: #1c1c1c;
					font-size: 52px;
					font-weight: 500;
					margin-bottom: 10px;
					letter-spacing: 2.2px;
					display: inline-flex;
				}
			}
		}
	}

	.section-two {
		.minititle {
			font-size: 20px;
			letter-spacing: 2.2px;
			font-weight: 500;
			color: #6b6b6b;
			text-transform: uppercase;
			margin-bottom: 50px;
		}
		.mission-container {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.title-holder {
			max-width: 1320px;
			margin: 0 auto;
			padding: 0 15px;
			margin-bottom: 10px;

			.filled-text {
				font-size: 35px;
				font-weight: 500;
				letter-spacing: 2.2px;
				padding: 5px 20px;
				width: max-content;
				margin-bottom: 20px;
				background-image: linear-gradient(to right, #fcb447 50%, #fff 50%),
					linear-gradient(to right, #fcb447 50%, #ffffff 50%);
				transition: background-position 1s;
				background-size: 200% 100%;
				background-repeat: no-repeat;
				background-position: 100% top, 100% top;
				background-clip: text, border-box;

				&.active {
					background-position: 0% top, 0% top;

					// &.second {
					// 	&::before {
					// 		background-position: 0% top, 0% top;
					// 	}
					// }
				}

				&.first {
					margin-left: 20px;
				}

				&.second {
					margin-left: -30px;
					position: relative;
					// background: transparent;

					// &::before {
					// 	content: "";
					// 	width: max-content;
					// 	left: -1500px;
					// 	background-image: linear-gradient(
					// 			to right,
					// 			#fcb447 50%,
					// 			#ffffff 50%
					// 		),
					// 		linear-gradient(to right, #fcb447 50%, #ffffff 50%);
					// 	transition: background-position 1s;
					// 	background-size: 200% 100%;
					// 	background-repeat: no-repeat;
					// 	background-position: 100% top, 100% top;
					// 	background-clip: text, border-box;

					// 	top: 0;
					// 	display: block;
					// 	height: 88px;
					// 	position: absolute;
					// 	z-index: -1;
					// 	overflow: hidden;
					// }
				}

				&.third {
					margin-left: 90px;
				}
			}
		}

		.title-mobile {
			display: none;
		}
		.mobile-wrapper {
			display: none;
		}

		.desktop-wrapper .description {
			font-size: 21px;
			font-weight: 400;
			max-width: 800px;
			padding-left: 60px;
		}
	}

	.vertical-tabs-holder {
		margin-bottom: 120px;

		.title-holder {
			margin-bottom: 80px;

			.minititle {
				font-size: 20px;
				color: #6b6b6b;
				text-align: left;
				font-weight: 500;
				margin-bottom: 40px;
				letter-spacing: 2.2px;
				text-transform: uppercase;
			}

			.title {
				font-size: 52px;
				font-weight: 500;
				letter-spacing: 2.2px;
				color: #1c1c1c;
				text-align: left;
				max-width: 1060px;
				width: 100%;
			}
		}

		.nav {
			border-left: 3px solid #ffdeaf;
			min-width: 230px;

			.nav-link {
				background: transparent;
				border: 0;
				font-family: "Roboto", sans-serif;
				padding: 24px 0 24px 24px;
				line-height: 38px;
				position: relative;
				border-radius: 3px;
				cursor: pointer;
				font-size: 25px;
				// font-weight: bold;
				color: #fcb02aa3;
				letter-spacing: 1.2px;
				text-align: left;

				&::after {
					content: "";
					width: 5px;
					background-color: #fcb447;
					height: 0;
					display: flex;
					position: absolute;
					left: -4px;
					border-radius: 3px;
					bottom: 0;
					transition-property: height;
					transition-duration: 0.3s;
					transition-timing-function: ease-out;
				}

				&.active {
					color: #fcb447;
					font-weight: 500;

					&::after {
						height: 100%;
						color: #1c1c1c;
						top: 0;
						transition-delay: 0.3s;
					}
				}
			}
		}

		.tab-content {
			width: 100%;

			.tab-pane {
				opacity: 0;
				visibility: hidden;
				padding: 72px 102px;
				padding-top: 0;
				position: absolute;
				width: 100%;
				left: 0;
				top: 0;
				display: flex;
				align-items: center;

				p {
					transform: translateY(-50px);
					opacity: 0;
					transition: transform 1s ease, opacity 1s ease;
					color: #1c1c1c;
					font-size: 25px;
					letter-spacing: 1.2px;
					font-weight: 400;
					text-align: justify;
				}

				&.active {
					position: static;
					opacity: 1;
					visibility: visible;

					p {
						transform: translateY(0);
						opacity: 1;
						color: #111;
					}
				}
			}
		}
	}

	.vertical-tabs-holder-mobile {
		display: none;
	}

	.section-hr-line {
		margin: 100px 0;
	}

	.what-makes-us-different-tabs {
		padding-bottom: 70px;

		.titles-holder {
			padding-top: 100px;
			margin-bottom: 80px;
			text-align: center;

			.minititle {
				font-size: 20px;
				color: #6b6b6b;
				text-align: left;
				font-weight: 400;
				margin-bottom: 40px;
				letter-spacing: 2.2px;
			}

			.section-title {
				font-size: 52px;
				font-weight: 500;
				letter-spacing: 2.2px;
				color: #1c1c1c;
				text-align: left;
				max-width: 1000px;
				width: 100%;
			}
		}

		.tabs-holder {
			.nav-tabs {
				border-bottom: 1px solid #ffd79f;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.nav-link {
					background: transparent;
					border: 0;
					font-family: "Roboto", sans-serif;

					.name {
						font-size: 25px;
						color: #fcb02aa3;
						letter-spacing: 1.2px;
						padding-bottom: 10px;
					}

					&.active {
						.number {
							color: #fcb02a;
						}

						.name {
							color: #fcb02a;
						}

						&::after {
							width: 100%;
							left: 0;
							transition-delay: 0.3s;
						}
					}

					&::after {
						content: "";
						width: 0;
						position: absolute;
						background-color: #fcb02a;
						height: 6px;
						display: flex;
						bottom: -1px;
						transition-property: width;
						transition-duration: 0.3s;
						transition-timing-function: ease-out;
						right: 0;
					}
				}
			}

			.tab-content {
				padding-top: 80px;
				padding-bottom: 100px;

				.tab-pane {
					.tab-body {
						font-size: 25px;
						max-width: 1000px;
						font-weight: 400;
						color: #1c1c1c;
						text-align: justify;
					}

					&.fade {
						transition: all 0.5s ease-in-out;
						transform: translateX(-100px);
						opacity: 0;

						&.show {
							transition: all 0.5s ease-in-out;
							transform: translateX(0rem);
							opacity: 1;
						}
					}
				}
			}
		}

		.button-holder {
			transition: transform 0.25s ease-in-out;
			min-height: 80px;
			//margin-top: 100px;
			max-width: 185px;
			min-width: 185px;

			.section-btn {
				background-color: #eda228;
				color: #fff;
				font-size: 25px;
				padding: 10px 25px;
				display: inline-flex;
				align-items: center;
				-webkit-transition: all 0.3s;
				-moz-transition: all 0.3s;
				transition: all 0.3s;
				font-weight: 400;
				max-width: 185px;
				min-width: 185px;
			}

			&:hover {
				.section-btn {
					background-color: #f4b959;

					.arrow-right {
						img {
							transform: translatex(10px);
							transition: transform 0.25s ease;
						}
					}
				}
			}
		}
	}

	.what-makes-us-different-mobile {
		display: none;
	}

	.get-to-know-us {
		background: #1c1c1c;
		padding: 30px 0;
		margin-bottom: 80px;

		.title {
			font-size: 52px;
			font-weight: 500;
			letter-spacing: 2.2px;
			color: #fff;
			text-align: center;
			max-width: 1000px;
			margin: 0 auto;
		}
	}

	.team-members {
		.team-member-holder {
			position: relative;
			z-index: 1;
			max-width: 330px;
			margin-bottom: 260px;

			.image-holder {
				position: relative;
				z-index: 10;
			}

			.member-info {
				max-width: 231px;

				.name {
					font-size: 25px;
					letter-spacing: 1.2px;
					font-weight: 500;
					padding-top: 30px;
					color: #1c1c1c;
				}

				.position {
					color: #6b6b6b;
					font-size: 15px;
					letter-spacing: 1.2px;
					font-weight: 500;
					text-transform: uppercase;
				}
			}

			.member-description {
				background: #fff;
				box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
				position: absolute;
				top: -40px;
				left: -40px;
				right: -40px;
				padding: 40px;
				z-index: 5;
				opacity: 0;
				pointer-events: none;
				transition: opacity 500ms ease-out;

				.name {
					color: #eda228;
					font-size: 25px;
					letter-spacing: 1.2px;
					font-weight: 500;
					padding-top: 30px;
				}

				.position {
					color: #6b6b6b;
					font-size: 15px;
					letter-spacing: 1.2px;
					font-weight: 500;
					text-transform: uppercase;
				}

				.description {
					color: #1c1c1c;
					font-size: 18px;
					letter-spacing: 1.2px;
					font-weight: 400;
					padding-top: 30px;
				}
			}

			&:hover {
				z-index: 10;
				transition: opacity 500ms ease-in;

				.member-description {
					opacity: 1;
					pointer-events: auto;

					.description-hover-title-holder {
						max-width: 231px;
					}
				}
			}
		}
	}

	.team-members-mobile {
		// display: none;
	}

	.section-three {
		position: relative;
		min-height: 652px;

		.section-background-image {
			background-image: url("/img/aboutus/logistics-background-image.png");
			width: 100%;
			min-height: 628px;
			max-height: 628px;
			position: absolute;
			top: 0;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			display: block;
			right: 0;
		}

		.titles-holder {
			height: 100%;
			/* display: flex; */
			min-height: 628px;
			max-height: 628px;
			padding: 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			position: relative;

			.minititle {
				font-size: 20px;
				color: #6b6b6b;
				text-align: left;
				font-weight: 500;
				margin-bottom: 40px;
				letter-spacing: 2.2px;
				text-transform: uppercase;
			}

			.section-title {
				font-size: 52px;
				font-weight: 500;
				letter-spacing: 2.2px;
				color: #1c1c1c;
				text-align: left;
				max-width: 1000px;
				width: 100%;
				margin-bottom: 30px;
			}

			.section-subtitle {
				font-size: 25px;
				font-weight: 400;
				color: #1c1c1c;
				line-height: 140%;
				text-align: justify;
				max-width: 600px;
				margin-bottom: 40px;
			}

			&::before {
				content: "";
				background: #fff;
				display: block;
				width: 5650px;
				position: absolute;
				top: 50px;
				height: 528px;
				left: -5000px;
			}

			.section-btn {
				color: #fff;
				background-color: #eda228;
				font-size: 25px;
				padding: 10px 25px;
				display: flex;
				justify-content: center;
				align-items: center;
				-webkit-transition: all 0.3s;
				-moz-transition: all 0.3s;
				transition: all 0.3s;
				font-weight: 400;
				min-width: 185px;
				max-width: 185px;

				&:hover {
					background-color: #f4b959;
				}
			}
		}
	}

	.section-four {
		background-color: #f6f9f8;
		padding: 60px 0;

		.titles-holder {
			text-align: center;
			margin-bottom: 160px;

			.section-title {
				font-size: 60px;
				font-weight: bold;
				color: #000;
				margin-bottom: 20px;
			}

			.section-subtitle {
				font-size: 18px;
				font-weight: 300;
				color: #000;
				max-width: 910px;
				margin: 0 auto;
			}
		}

		.boxes-holder {
			.box {
				margin-bottom: 5%;

				.icon-holder {
					min-height: 100px;
					margin-bottom: 40px;
				}

				.box-titles-holder {
					.box-title {
						font-size: 37px;
						color: #000;
						margin-bottom: 25px;
					}

					.box-subititle {
						font-size: 16px;
						font-weight: 300;
						max-width: 430px;
					}
				}
			}
		}

		.button-holder {
			text-align: center;

			.section-btn {
				font-size: 35px;
				font-weight: 300;
				color: #efa227;
				border: 4px solid #efa227;
				padding: 15px 20px;
				display: inline-block;
				transition: transform 0.25s ease;

				.arrow-right {
					margin-left: 12px;

					img {
						transition: transform 0.25s ease-in-out;
					}
				}

				&:hover {
					transform: scale(1.1);

					.arrow-right {
						img {
							transform: translatex(10px);
							transition: transform 0.25s ease;
						}
					}
				}
			}
		}
	}

	.section-five {
		background-color: #20201f;
		padding: 80px 0 70px 0;

		.content-holder {
			display: flex;
			flex-direction: column;
			align-items: center;

			.image-holder {
				margin-bottom: 60px;
			}

			.list-holder {
				.list-item {
					.zero {
						color: #fff;
						font-family: "Roboto Condensed", sans-serif;
						font-size: 45px;
					}

					font-family: "Roboto Condensed", sans-serif;
					font-size: 45px;
					color: #c1c2c3;
				}
			}
		}
	}

	.section-six {
		position: relative;
		min-height: 608px;
		margin: 0px 0;

		.section-background-image {
			background-image: url("/img/aboutpage-section-6-image.png");
			width: 45%;
			min-height: 608px;
			max-height: 608px;
			position: absolute;
			top: 0;
			left: 0;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			display: block;
		}

		.firstCol {
			padding-left: 60px;

			.titles-holder {
				padding-top: 120px;

				.section-title {
					color: #1c1c1c;
					font-size: 52px;
					margin-bottom: 15px;
					letter-spacing: 2.2px;
					font-weight: 500;
				}

				.section-subtitle {
					font-size: 25px;
					font-weight: 400;
					color: #1c1c1c;
					line-height: 140%;
					text-align: justify;
				}
			}
		}
	}

	.section-seven {
		padding: 70px 0;

		.titles-holder {
			text-align: center;

			.section-title {
				color: #1c1c1c;
				font-size: 52px;
				margin-bottom: 15px;
				letter-spacing: 2.2px;
				font-weight: 500;
			}

			.section-subtitle {
				color: #1c1c1c;
				font-size: 25px;
				max-width: 750px;
				font-weight: 400;
				margin: 0 auto;
				letter-spacing: 1.2px;
				margin-bottom: 50px;
				text-align: center;
			}
		}

		.button-holder {
			text-align: center;

			.section-btn {
				background-color: #eda228;
				color: #fff;
				font-size: 25px;
				padding: 10px 25px;
				display: inline-flex;
				align-items: center;
				-webkit-transition: all 0.3s;
				-moz-transition: all 0.3s;
				transition: all 0.3s;
				font-weight: 500;
				max-width: 185px;
				min-width: 185px;

				&:hover {
					background: #f4b959;
				}
			}
		}
	}

	.section-eight {
		background-color: #1c1c1c;
		padding: 80px 0;

		.image-holder {
			text-align: center;
			margin-bottom: 50px;
		}

		.text-holder {
			text-align: center;
			margin-bottom: 50px;

			p {
				font-size: 28px;
				font-weight: 300;
				color: #fff;
				max-width: 770px;
				margin: 0 auto;
				width: 100%;
				line-height: 120%;
			}
		}

		.subscribe-holder {
			max-width: 380px;
			margin: 0 auto;

			.input-group {
				border-bottom: 1px solid #6b6b6b;
				padding-bottom: 5px;

				input {
					border-left: 0;
					border-radius: 0;
					background-color: transparent;
					padding-left: 10px;
					border: 0;
					padding-bottom: 0;
					color: #fff;

					&::placeholder {
						color: #6b6b6b;
					}

					&:focus {
						color: #fff;
						border: 0;
						background: transparent;
						box-shadow: none;
					}
				}

				.input-group-append {
					border-radius: 0;
					border: 0;
					border-right: 0;
					padding: 6px 17px;

					img {
						max-height: 16px;
					}
				}

				.count-me-in {
					border-radius: 0;
					border: 0;
					border-right: 0;
					background: transparent;
					color: #fff;

					img {
						max-width: 30px;
						position: relative;
						top: -3px;
						right: 17px;
					}
				}
			}
		}
	}
}

.contactpage-holder {
	header {
		background: #fff;
		position: relative;

		// .header-holder {
		// 	.mobile-holder-flex {
		// 		.header-navigation-menu {
		// 		  .navbar-nav {
		// 		    .nav-link {
		// 		      color: #1c1c1c;

		// 		      &:hover {
		// 		        color: #eda228;
		// 		      }

		// 		      &.join-us-nav-link {
		// 		        color: #fff;
		// 		      }
		// 		    }
		// 		  }
		// 		}
		// 	}
		// }
	}

	.beer {
		font-family: "Waiting for the Sunrise", cursive;
		text-transform: uppercase;
		font-weight: bold;
		font-size: 1em;
	}

	.section-one {
		margin: 200px 0 100px 0;

		.title-holder {
			max-width: 1320px;
			margin: 0 auto;
			margin-bottom: 10px;

			.filled-text {
				font-size: 52px;
				font-weight: 500;
				letter-spacing: 2.2px;
				padding: 5px 20px;
				width: max-content;
				max-width: 100%;
				margin-bottom: 20px;
				background-image: linear-gradient(to right, #fcb447 50%, #fff 50%),
					linear-gradient(to right, #fcb447 50%, #ffffff 50%);
				transition: background-position 1s;
				background-size: 200% 100%;
				background-repeat: no-repeat;
				background-position: 100% top, 100% top;
				background-clip: text, border-box;
				margin-bottom: 40px;

				&.active {
					background-position: 0% top, 0% top;

					&.second {
						&::before {
							background-position: 0% top, 0% top;
						}
					}
				}

				&.second {
					margin-left: -30px;
					position: relative;
					// background: transparent;

					// &::before {
					// 	content: "";
					// 	width: max-content;
					// 	left: -1500px;
					// 	background-image: linear-gradient(to right, lightblue 50%, blue 50%),
					// 		linear-gradient(to right, #fcb447 50%, #ffffff 50%);
					// 	transition: background-position 1s;
					// 	background-size: 200% 100%;
					// 	background-repeat: no-repeat;
					// 	background-position: 100% top, 100% top;
					// 	background-clip: text, border-box;

					// 	top: 0;
					// 	display: block;
					// 	height: 88px;
					// 	position: absolute;
					// 	z-index: -1;
					// 	overflow: hidden;
					// }
				}

				&.third {
					margin-left: 90px;
				}
			}

			.text-field {
				font-size: 25px;
				font-weight: 400;
				color: #1c1c1c;
				line-height: 140%;
				max-width: 600px;
				text-align: justify;

				span {
					text-decoration: line-through;
				}
			}

			.filled-text-mobile-holder {
				display: none;
			}
		}
	}

	.section-three {
		margin-bottom: 70px;

		.contact-form-holder {
			form {
				.two-form-group-holder {
					display: flex;
					align-items: center;
					justify-content: space-between;

					.form-group {
						width: 100%;
						margin-right: 2%;
						max-width: 49%;
						margin-bottom: 2%;

						&:nth-last-child(1) {
							margin-right: 0;
						}
					}
				}

				.form-group {
					max-width: 100%;
					margin-bottom: 2%;

					input {
						min-height: 45px;
					}
				}
			}

			.button-holder {
				.section-btn {
					background-color: #eda228;
					color: #fff;
					font-size: 25px;
					padding: 10px 25px;
					display: inline-flex;
					align-items: center;
					-webkit-transition: all 0.3s;
					-moz-transition: all 0.3s;
					transition: all 0.3s;
					font-weight: 500;
					max-width: 185px;
					min-width: 185px;
					justify-content: center;
					border: none;

					&:hover {
						background: #f4b959;
					}
				}
			}
		}
	}

	.section-five {
		background-color: #1c1c1c;
		padding: 80px 0;

		.image-holder {
			text-align: center;
			margin-bottom: 50px;
		}

		.text-holder {
			text-align: center;
			margin-bottom: 50px;

			p {
				font-size: 28px;
				font-weight: 300;
				color: #fff;
				max-width: 770px;
				margin: 0 auto;
				width: 100%;
				line-height: 120%;
			}
		}

		.subscribe-holder {
			max-width: 380px;
			margin: 0 auto;

			.input-group {
				border-bottom: 1px solid #6b6b6b;
				padding-bottom: 5px;

				input {
					border-left: 0;
					border-radius: 0;
					background-color: transparent;
					padding-left: 10px;
					border: 0;
					padding-bottom: 0;
					color: #fff;

					&::placeholder {
						color: #6b6b6b;
					}

					&:focus {
						color: #fff;
						border: 0;
						background: transparent;
						box-shadow: none;
					}
				}

				.input-group-append {
					border-radius: 0;
					border: 0;
					border-right: 0;
					padding: 6px 17px;

					img {
						max-height: 16px;
					}
				}

				.count-me-in {
					border-radius: 0;
					border: 0;
					border-right: 0;
					background: transparent;
					color: #fff;

					img {
						max-width: 30px;
						position: relative;
						top: -3px;
						right: 17px;
					}
				}
			}
		}
	}
}

.faqpage-holder {
	header {
		background: #fff;
		position: relative;

		// .header-holder {
		// 	.mobile-holder-flex {
		// 		.header-navigation-menu {
		// 		  .navbar-nav {
		// 		    .nav-link {
		// 		      color: #1c1c1c;

		// 		      &:hover {
		// 		        color: #eda228;
		// 		      }

		// 		      &.join-us-nav-link {
		// 		        color: #fff;
		// 		      }
		// 		    }
		// 		  }
		// 		}
		// 	}
		// }
	}

	.section-one {
		margin: 200px 0 100px 0;

		.title-holder {
			max-width: 1320px;
			margin: 0 auto;
			padding: 0 15px;
			margin-bottom: 10px;

			.ft {
				position: relative;
				font-size: 52px;
				font-weight: 500;
				letter-spacing: 2.2px;
				padding: 5px 20px;
				width: max-content;

				&::after {
					content: "";
					position: absolute;
					top: 0;
					min-height: 100%;
					left: 0;
					width: 100%;
					z-index: -1;
				}
			}

			.filled-text {
				font-size: 52px;
				font-weight: 500;
				letter-spacing: 2.2px;
				padding: 5px 20px;
				width: max-content;
				max-width: 100%;
				margin-bottom: 20px;
				background-image: linear-gradient(to right, #fcb447 50%, #fff 50%),
					linear-gradient(to right, #fcb447 50%, #ffffff 50%);
				transition: background-position 1s;
				background-size: 200% 100%;
				background-repeat: no-repeat;
				background-position: 100% top, 100% top;
				background-clip: text, border-box;

				&.active {
					background-position: 0% top, 0% top;

					&.second {
						&::before {
							background-position: 0% top, 0% top;
						}
					}
				}

				&.third {
					margin-bottom: 0;
				}
			}
		}
	}

	.hr-line-full {
		margin-top: 30px;
		margin-bottom: 80px;
	}

	.section-three {
		margin-bottom: 100px;

		.vertical-tabs-holder {
			margin-bottom: 120px;

			.title {
				margin-bottom: 60px;
			}

			.nav {
				border-left: 3px solid #ffdeaf;
				min-width: 230px;

				.nav-link {
					background: transparent;
					border: 0;
					font-family: "Roboto", sans-serif;
					padding: 24px 0 24px 24px;
					line-height: 38px;
					position: relative;
					border-radius: 3px;
					cursor: pointer;
					font-size: 25px;
					// font-weight: bold;
					color: #fcb02aa3;
					letter-spacing: 1.2px;
					text-align: left;

					&::after {
						content: "";
						width: 5px;
						background-color: #fcb447;
						height: 0;
						display: flex;
						position: absolute;
						left: -4px;
						border-radius: 3px;
						bottom: 0;
						transition-property: height;
						transition-duration: 0.3s;
						transition-timing-function: ease-out;
					}

					&.active {
						color: #fcb447;
						font-weight: 500;

						&::after {
							height: 100%;
							color: #1c1c1c;

							top: 0;
							transition-delay: 0.3s;
						}
					}
				}
			}

			// .nav {
			// 	border-left: 3px solid #ffdeaf;
			// 	min-width: 230px;

			// 	.nav-link {
			// 		background: transparent;
			// 		border: 0;
			// 		font-family: "Roboto", sans-serif;
			// 		padding: 24px 0 24px 24px;
			// 		line-height: 38px;
			// 		position: relative;
			// 		border-radius: 3px;
			// 		cursor: pointer;
			// 		font-size: 25px;
			// 		font-weight: bold;
			// 		color: #fcb02aa3;
			// 		letter-spacing: 1.2px;
			// 		text-align: left;

			// 		&::after {
			// 			content: "";
			// 			width: 5px;
			// 			background-color: #fcb447;
			// 			height: 0;
			// 			display: flex;
			// 			position: absolute;
			// 			left: -4px;
			// 			border-radius: 3px;
			// 			bottom: 0;
			// 			transition-property: height;
			// 			transition-duration: 0.3s;
			// 			transition-timing-function: ease-out;
			// 		}

			// 		&.active {
			// 			color: #fcb447;

			// 			&::after {
			// 				height: 100%;
			// 				color: #1c1c1c;
			// 				font-weight: 600;
			// 				top: 0;
			// 				transition-delay: 0.3s;
			// 			}
			// 		}
			// 	}
			// }

			.tab-content {
				width: 100%;

				.tab-pane {
					opacity: 0;
					visibility: hidden;
					padding: 72px 102px;
					padding-top: 0;
					position: absolute;
					width: 100%;
					left: 0;
					top: 0;
					display: block;
					align-items: center;

					p {
						transform: translateY(-50px);
						opacity: 0;
						transition: transform 1s ease, opacity 1s ease;
						color: #1c1c1c;
						font-size: 25px;
						letter-spacing: 1.2px;
						font-weight: 400;
					}

					&.active {
						position: static;
						opacity: 1;
						visibility: visible;

						p {
							transform: translateY(0);
							opacity: 1;
							color: #111;
						}
					}
				}
			}
		}

		.accordion {
			.title {
				font-size: 40px;
				color: #000;
				margin-bottom: 40px;
			}

			.accordion-item {
				.accordion-header {
					.accordion-button {
						border: 0;
						border-bottom: 1px solid #edecec;

						span {
							padding-left: 40px;
							font-size: 20px;
							color: #000;
							font-weight: 500;
						}

						&:not(.collapsed) {
							background: transparent;

							span {
								padding-left: 40px;
							}

							&::after {
								background-image: url("/img/accordion-bottom-arrow.png");
								width: 15px;
								height: 11px;
								left: 16px;
								transform: rotate(0);
							}
						}

						&::after {
							position: absolute;
							left: 20px;
							background-image: url("/img/accordion-right-arrow.png");
							width: 11px;
							height: 15px;
							background-size: auto;
						}

						&:focus {
							box-shadow: none;
						}
					}
				}

				.accordion-body {
					font-size: 12px;
					color: #000;
					font-weight: 500;
					padding-left: 80px;
					text-align: justify;
				}
			}
		}
	}

	.section-faq-mobile {
		display: none;
	}

	.section-four {
		padding: 70px 0;

		.titles-holder {
			text-align: center;

			.section-title {
				color: #1c1c1c;
				font-size: 52px;
				font-weight: 500;
				letter-spacing: 2.2px;
			}

			.section-subtitle {
				color: #1c1c1c;
				font-size: 28px;
				letter-spacing: 1.2px;
				font-weight: 400;
				max-width: 850px;
				margin: 0 auto;
				margin-bottom: 50px;
			}
		}

		.button-holder {
			text-align: center;

			.section-btn {
				background-color: #eda228;
				color: #fff;
				font-size: 25px;
				padding: 5px 15px;
				display: inline-flex;
				align-items: center;
				-webkit-transition: all 0.3s;
				-moz-transition: all 0.3s;
				transition: all 0.3s;
				font-weight: 400;
				max-width: 235px;

				&:hover {
					background-color: #f4b959;
				}
			}
		}
	}

	.section-five {
		background-color: #1c1c1c;
		padding: 80px 0;

		.image-holder {
			text-align: center;
			margin-bottom: 50px;
		}

		.text-holder {
			text-align: center;
			margin-bottom: 50px;

			p {
				font-size: 28px;
				font-weight: 300;
				color: #fff;
				max-width: 770px;
				margin: 0 auto;
				width: 100%;
				line-height: 120%;
			}
		}

		.subscribe-holder {
			max-width: 380px;
			margin: 0 auto;

			.input-group {
				border-bottom: 1px solid #6b6b6b;
				padding-bottom: 5px;

				input {
					border-left: 0;
					border-radius: 0;
					background-color: transparent;
					padding-left: 10px;
					border: 0;
					padding-bottom: 0;
					color: #fff;

					&::placeholder {
						color: #6b6b6b;
					}

					&:focus {
						color: #fff;
						border: 0;
						background: transparent;
						box-shadow: none;
					}
				}

				.input-group-append {
					border-radius: 0;
					border: 0;
					border-right: 0;
					padding: 6px 17px;

					img {
						max-height: 16px;
					}
				}

				.count-me-in {
					border-radius: 0;
					border: 0;
					border-right: 0;
					background: transparent;
					color: #fff;

					img {
						max-width: 30px;
						position: relative;
						top: -3px;
						right: 17px;
					}
				}
			}
		}
	}

	.section-six {
		background: #f3f3f3;
		padding: 100px 0;

		.firstCol {
			.titles-holder {
				.section-title {
					font-size: 52px;
					font-weight: 500;
					color: #1c1c1c;
					letter-spacing: 2.2px;
				}

				.section-subtitle {
					font-size: 25px;
					letter-spacing: 1.2px;
					font-weight: 400;
					color: #1c1c1c;
					line-height: 140%;
					max-width: 410px;
					text-align: justify;
				}
			}
		}

		.secondCol {
			.holder {
				display: flex;
				min-height: 360px;

				.leftside {
					min-width: 40%;
					max-width: 40%;
					text-align: center;
				}

				.rightside {
					.text-holder {
						margin-left: 40px;

						.eebook-holder {
							color: #1c1c1c;
							font-size: 20px;
							font-weight: 400;
							letter-spacing: 2.2px;
							margin-bottom: 20px;

							.eebook {
								color: #1c1c1c;
								font-size: 15px;
								font-weight: 400;
								letter-spacing: 1.2px;
							}

							.pdf {
								img {
									max-width: 36px;
								}
							}
						}

						.box-title {
							font-size: 22px;
							font-weight: 500;
							letter-spacing: 1.2px;
							color: #1c1c1c;
							text-align: justify;
						}

						.box-subititle {
							font-size: 20px;
							font-weight: 400;
							letter-spacing: 1.2px;
							color: #1c1c1c;
							margin-bottom: 20px;
							text-align: justify;
						}

						.author-date-info {
							margin-bottom: 20px;

							.author {
								color: #1c1c1c;
								font-size: 15px;
								letter-spacing: 1.2px;
								font-weight: 400;
							}

							.date {
								color: #1c1c1c;
								font-size: 15px;
								letter-spacing: 1.2px;
								font-weight: 400;
							}
						}

						.button-holder {
							.section-btn {
								background-color: #eda228;
								color: #fff;
								font-size: 25px;
								padding: 5px 15px;
								display: inline-flex;
								align-items: center;
								-webkit-transition: all 0.3s;
								-moz-transition: all 0.3s;
								transition: all 0.3s;
								font-weight: 500;
								max-width: 235px;
								letter-spacing: 1.2px;

								&:hover {
									background-color: #f4b959;
								}
							}

							.all-res {
								font-size: 25px;
								color: #eda228;
								font-weight: 400;
								line-height: 58px;
								text-decoration: underline;
								text-underline-offset: 3px;

								&:hover {
									color: #f4b959;
								}
							}
						}
					}
				}
			}
		}

		.download-button-holder {
			margin: 120px auto 100px auto;
			text-align: center;

			.section-btn {
				font-size: 33px;
				color: #fff;
				background: #efa227;
				padding: 10px 25px;
			}
		}
	}
}

.survey-page {
	.section-one {
		background: "#f4f4f4";
		margin: 100px 0;

		.survey-header {
			display: flex;
			align-items: center;

			& > * {
				display: inline-block;
			}
		}

		.title-holder {
			max-width: 1320px;
			margin: 0 auto;
			padding: 0 15px;
			margin-bottom: 10px;

			.ft {
				position: relative;
				font-size: 52px;
				font-weight: 500;
				letter-spacing: 2.2px;
				padding: 5px 20px;
				width: max-content;

				&::after {
					content: "";
					position: absolute;
					top: 0;
					min-height: 100%;
					left: 0;
					width: 100%;
					z-index: -1;
				}
			}

			.filled-text {
				font-size: 40px;
				font-weight: 500;
				letter-spacing: 2.2px;
				padding: 5px 20px;
				width: max-content;
				max-width: 100%;
				margin-bottom: 20px;
				background-image: linear-gradient(to right, #fcb447 50%, #fff 50%),
					linear-gradient(to right, #fcb447 50%, #ffffff 50%);
				transition: background-position 1s;
				background-size: 200% 100%;
				background-repeat: no-repeat;
				background-position: 100% top, 100% top;
				background-clip: text, border-box;

				&.active {
					background-position: 0% top, 0% top;

					&.second {
						&::before {
							background-position: 0% top, 0% top;
						}
					}
				}

				&.third {
					margin-bottom: 0;
				}
			}
		}
	}

	.section-two {
		margin: 100px 0;

		.wrapper {
			display: flex;
			justify-content: center;
			align-items: center;

			.disclaimer {
				width: 920px;
				height: 298px;
				margin-left: 10px;

				& > p {
					font-size: 16px;
					line-height: 28px;
				}
			}
		}
	}
}

.survey-frame {
	display: block;
	border: none; /* Reset default border */
	min-height: 240vh; /* Viewport-relative units */
	overflow: hidden;
}

.thanks {
	max-width: 100%;
	text-align: center;
}

.sotirForm {
	margin: 100px 0;
	display: flex;
	justify-content: center;
	align-items: center;

	form {
		width: 30%;
	}
}

.sotirForm input[type="email"] {
	width: 55%;
	float: left;
	height: 40px;
	padding: 9px 10px;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	color: #33475b;
	background-color: #f5f8fa;
	border: 1px solid #cbd6e2;
	box-sizing: border-box;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
}

.sotirForm input[type="submit"] {
	cursor: pointer;
	font-weight: 700;
	text-align: center;
	background: #4b9ad4;
	border-color: #4b9ad4;
	color: #ffffff;
	font-family: arial, helvetica, sans-serif;
	line-height: 16px;
	font-size: 12px;
	border-radius: 3px;
	font-size: 14px;
	padding: 12px 24px;
	width: 42%;
	float: left;
}

.sotirForm input[type="submit"]:after {
	content: "";
	display: block;
	background-image: url("/img/plane-orange-icon1.png");
	width: 16px;
	height: 16px;
	margin-left: 100px;
	position: absolute;
}

.containerMainLoading {
	color: #fcb53b;
}

.reviewspage-holder {
	header {
		background: #fff;
		position: relative;

		// .header-holder {
		// 	.mobile-holder-flex {
		// 		.header-navigation-menu {
		// 		  .navbar-nav {
		// 		    .nav-link {
		// 		      color: #1c1c1c;

		// 		      &:hover {
		// 		        color: #eda228;
		// 		      }

		// 		      &.join-us-nav-link {
		// 		        color: #fff;
		// 		      }
		// 		    }
		// 		  }
		// 		}
		// 	}
		// }
	}

	.section-one {
		margin-top: 200px;

		.titles-holder {
			.filled-text {
				font-size: 52px;
				font-weight: 500;
				letter-spacing: 2.2px;
				padding: 5px 20px;
				width: max-content;
				max-width: 100%;
				margin-bottom: 20px;
				background-image: linear-gradient(to right, #fcb447 50%, #fff 50%),
					linear-gradient(to right, #fcb447 50%, #ffffff 50%);
				transition: background-position 1s;
				background-size: 200% 100%;
				background-repeat: no-repeat;
				background-position: 100% top, 100% top;
				background-clip: text, border-box;
				margin-bottom: 40px;

				&.active {
					background-position: 0% top, 0% top;

					&.second {
						&::before {
							background-position: 0% top, 0% top;
						}
					}
				}
			}

			.text-field {
				color: #1c1c1c;
				font-size: 25px;
				font-weight: 400;
				letter-spacing: 1.2px;
				text-align: justify;
			}
		}
	}

	.hr-line-full {
		margin: 100px 0;
	}

	.section-two {
		.titles-holder {
			display: flex;
			align-items: center;
			margin-bottom: 90px;

			h2 {
				margin-bottom: 0;
				font-size: 28px;
				font-weight: 500;
				letter-spacing: 2.2px;
				margin-right: 30px;
			}
		}

		.reviews-boxes-holder {
			.first-block {
				margin-bottom: 20px;

				.leftside {
					.firstCol {
						border: 1px solid #000;
						margin-bottom: 20px;

						.text-holder {
							padding: 140px 0;
							max-width: 450px;
							margin: 0 auto;

							.review-text {
								margin-bottom: 30px;
								color: #1c1c1c;
								position: relative;

								.mark-up {
									position: absolute;
									left: -40px;
									top: -10px;
								}

								.mark-down {
									position: relative;
									bottom: -10px;
									right: -20px;
								}
							}

							.client-info {
								.client-name {
									color: #faa819;
									font-size: 20px;
								}

								.client-website {
									font-size: 20px;
								}
							}
						}
					}

					.secondCol {
						@media screen and (max-width: 772px) {
							margin-bottom: 20px;
						}
						.text-holder {
							background: #1b1b1b;
							max-width: 95%;
							padding: 40px;
							height: 100%;

							@media screen and (max-width: 772px) {
								max-width: 100%;
								margin-bottom: 20px;
							}

							.review {
								color: #fff;
								font-size: 52px;
								font-weight: 300;
								line-height: 130%;
								margin: 40px 0;

								span {
									font-weight: bold;
								}
							}

							.client-info {
								.client-name {
									color: #fff;
									font-size: 20px;
								}

								.client-website {
									color: #fff;
									font-size: 20px;
								}
							}
						}
					}

					.thirdCol {
						border: 1px solid #000;
						padding: 16px;
						min-height: 438px;
						.text-holder {
							height: 100%;
							display: flex;
							flex-direction: column;
							justify-content: center;

							.review-text {
								text-align: justify;
								margin-bottom: 40px;
							}

							.client-info {
								.client-name {
									color: #faa819;
								}
							}
						}
					}
				}

				.rightside {
					margin-left: 20px;

					.box {
						background: #f5aa33;
						padding: 54px 30px;

						.text-holder {
							padding-left: 60px;
							margin-top: 10px;

							.quote {
								margin-bottom: 40px;
								color: #fff;
								font-size: 42px;
								letter-spacing: 1.2px;
							}

							.client-info {
								p {
									color: #fff;
									font-size: 20px;
								}
							}
						}
					}

					.secondCol {
						margin: 13px 0;

						.image {
							background-image: url("/img/reviewspage/review-image.jpg");
							display: flex;
							position: relative;
							overflow: hidden;
							max-width: 100%;
							min-height: 270px;
							max-height: 343px;
							background-repeat: no-repeat;
							background-position: center;
							background-size: cover;
						}
					}
				}
			}

			.second-block {
				margin-bottom: 20px;

				.leftside {
					.box {
						background: #f5aa33;
						padding: 40px;

						.text-holder {
							padding-left: 60px;
							margin-top: 10px;

							.quote {
								margin-bottom: 40px;
								color: #fff;
								font-size: 42px;
								letter-spacing: 1.2px;
							}

							.client-info {
								p {
									color: #fff;
									font-size: 20px;
								}
							}
						}
					}

					.firstCol {
						.image {
							background-image: url("/img/reviewspage/review-image.jpg");
							display: flex;
							position: relative;
							overflow: hidden;
							max-width: 100%;
							min-height: 270px;
							max-height: 343px;
							background-repeat: no-repeat;
							background-position: center;
							background-size: cover;
						}
					}

					.secondCol {
						margin: 20px 0;
					}

					.thirdCol {
						.image {
							background-image: url("/img/reviewspage/review-image.jpg");
							display: flex;
							position: relative;
							overflow: hidden;
							max-width: 100%;
							min-height: 270px;
							max-height: 343px;
							background-repeat: no-repeat;
							background-position: center;
							background-size: contain;
							text-align: center;
						}
					}
				}

				.rightside {
					margin-left: 20px;

					.firstCol {
						border: 1px solid #000;
						margin-bottom: 20px;

						.text-holder {
							padding: 100px 0;
							max-width: 450px;
							margin: 0 auto;

							.review-text {
								margin-bottom: 30px;
								color: #1c1c1c;
								position: relative;

								.mark-up {
									position: absolute;
									left: -40px;
									top: -10px;
								}

								.mark-down {
									position: relative;
									bottom: -10px;
									right: -20px;
								}
							}

							.client-info {
								.client-name {
									color: #faa819;
									font-size: 20px;
								}

								.client-website {
									font-size: 20px;
								}
							}
						}
					}

					.thirdCol {
						.text-holder {
							background: #1b1b1b;
							max-width: 95%;
							padding: 40px;
							margin-left: 20px;
							height: 100%;
							color: white;

							img {
								margin-bottom: 20px;
							}

							.review {
								color: #fff;
								font-size: 52px;
								font-weight: 300;
								line-height: 130%;
								margin: 40px 0;

								span {
									font-weight: bold;
								}
							}

							.client-info {
								margin-top: 20px;
								.client-name {
									color: #fff;
									font-size: 20px;
								}

								.client-website {
									color: #fff;
									font-size: 20px;
								}
							}
						}
					}

					.secondCol {
						border: 1px solid #000;
						padding: 16px;

						.text-holder {
							padding: 16px;
							height: 100%;
							display: flex;
							flex-direction: column;
							justify-content: center;

							.review-text {
								text-align: justify;
								margin-bottom: 40px;
							}

							.client-info {
								.client-name {
									color: #faa819;
								}
							}
						}
					}
				}
			}

			.third-block {
				.block-holder {
					display: flex;

					.text-holder {
						border: 1px solid #000;
						// max-width: 928px;
						// min-width: 928px;
						padding: 100px 0;
						flex: 2;
						margin-right: 20px;

						.holder {
							/* padding: 100px 0; */
							max-width: 450px;
							margin: 0 auto;

							.review-text {
								margin-bottom: 30px;
								color: #1c1c1c;
								position: relative;

								.mark-up {
									position: absolute;
									left: -40px;
									top: -10px;
								}

								.mark-down {
									position: relative;
									bottom: -10px;
									right: -20px;
								}
							}

							.client-info {
								.client-name {
									color: #faa819;
									font-size: 20px;
								}

								.client-website {
									font-size: 20px;
								}
							}
						}
					}

					.image-holder {
						max-width: 100%;
						flex: 0 1;

						.image {
							//background-image: url("/img/reviewspage/image-third-block.jpg");
							display: flex;
							position: relative;
							overflow: hidden;
							min-width: 346px;
							min-height: 381px;
							// max-height: 400px;
							background-repeat: no-repeat;
							background-position: center;
							background-size: cover;
							display: block;
							@media (max-width: 992px) {
								min-width: auto;
							}
						}
					}
				}
			}
		}
	}

	.section-four {
		padding: 70px 0;
		padding-top: 140px;

		.titles-holder {
			text-align: center;

			.section-title {
				color: #1c1c1c;
				font-size: 52px;
				margin-bottom: 15px;
				letter-spacing: 2.2px;
				font-weight: 500;
			}

			.section-subtitle {
				color: #1c1c1c;
				font-size: 28px;
				max-width: 750px;
				font-weight: 400;
				margin: 0 auto;
				letter-spacing: 1.2px;
				margin-bottom: 50px;
			}
		}

		.button-holder {
			text-align: center;

			.section-btn {
				background-color: #eda228;
				color: #fff;
				font-size: 25px;
				padding: 10px 25px;
				display: inline-flex;
				align-items: center;
				-webkit-transition: all 0.3s;
				-moz-transition: all 0.3s;
				transition: all 0.3s;
				font-weight: 400;
				max-width: 185px;
				min-width: 185px;

				&:hover {
					background: #f4b959;
				}
			}
		}
	}

	.section-five {
		background-color: #1c1c1c;
		padding: 80px 0;

		.image-holder {
			text-align: center;
			margin-bottom: 50px;
		}

		.text-holder {
			text-align: center;
			margin-bottom: 50px;

			p {
				font-size: 28px;
				font-weight: 300;
				color: #fff;
				max-width: 770px;
				margin: 0 auto;
				width: 100%;
				line-height: 120%;
			}
		}

		.subscribe-holder {
			max-width: 380px;
			margin: 0 auto;

			.input-group {
				border-bottom: 1px solid #6b6b6b;
				padding-bottom: 5px;

				input {
					border-left: 0;
					border-radius: 0;
					background-color: transparent;
					padding-left: 10px;
					border: 0;
					padding-bottom: 0;
					color: #fff;

					&::placeholder {
						color: #6b6b6b;
					}

					&:focus {
						color: #fff;
						border: 0;
						background: transparent;
						box-shadow: none;
					}
				}

				.input-group-append {
					border-radius: 0;
					border: 0;
					border-right: 0;
					padding: 6px 17px;

					img {
						max-height: 16px;
					}
				}

				.count-me-in {
					border-radius: 0;
					border: 0;
					border-right: 0;
					background: transparent;
					color: #fff;

					img {
						max-width: 30px;
						position: relative;
						top: -3px;
						right: 17px;
					}
				}
			}
		}
	}
}

.inventorypage1-holder {
	header {
		background: #fff;
		position: relative;

		// .header-holder {
		// 	.mobile-holder-flex {
		// 		.header-navigation-menu {
		// 		  .navbar-nav {
		// 		    .nav-link {
		// 		      color: #1c1c1c;

		// 		      &:hover {
		// 		        color: #eda228;
		// 		      }

		// 		      &.join-us-nav-link {
		// 		        color: #fff;
		// 		      }
		// 		    }
		// 		  }
		// 		}
		// 	}
		// }
	}

	.section-one {
		position: relative;
		margin-top: 200px;
		margin-bottom: 80px;

		.text-holder {
			.titles-holder {
				margin-bottom: 50px;

				p {
					color: #1c1c1c;
					font-size: 25px;
					font-weight: 400;
					margin-bottom: 50px;
					letter-spacing: 1.2px;
					max-width: 840px;
					margin: 0 auto;
					text-align: center;
				}
			}
		}

		.button-holder {
			text-align: center;

			.section-btn {
				background-color: #eda228;
				color: #fff;
				font-size: 25px;
				padding: 10px 25px;
				display: inline-flex;
				align-items: center;
				-webkit-transition: all 0.3s;
				-moz-transition: all 0.3s;
				transition: all 0.3s;
				font-weight: 400;
				max-width: 185px;
				min-width: 185px;

				&:hover {
					background: #f4b959;
				}
			}
		}
	}

	.hr-line {
		margin: 80px auto;
	}

	.section-two {
		margin-bottom: 120px;

		.titles-holder {
			text-align: center;
			margin-bottom: 120px;

			.section-title {
				font-size: 52px;
				color: #1c1c1c;
				text-align: center;
				margin-bottom: 15px;
				font-weight: 500;
				letter-spacing: 1.2px;
			}

			.section-subtitle {
				font-size: 28px;
				color: #1c1c1c;
				text-align: center;
				letter-spacing: 1.2px;
				font-weight: 400;
			}
		}

		.boxes-holder {
			.box {
				max-width: 416px;
				position: relative;
				overflow: hidden;

				.image-holder {
					img {
						transform: scale(1);
						-webkit-transform: scale(1);
						transition: all 0.4s linear;
						-webkit-transition: all 0.4s linear;
						-moz-transition: all 0.4s linear;
						-o-transition: all 0.4s linear;
					}
				}

				.text-holder {
					position: absolute;
					bottom: 20px;
					width: 100%;
					margin: 0 auto;
					text-align: center;

					p {
						color: #fff;
						font-size: 60px;
						font-weight: bold;
					}
				}

				.hover-text {
					display: none;
				}

				&:hover {
					.image-holder {
						img {
							transform: scale(1.03);
							-webkit-transform: scale(1.03);
						}
					}

					.hover-text {
						position: absolute;
						top: 0;
						bottom: 0;
						background: #eda22833;
						/* opacity: .7; */
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: center;

						.view-now {
							font-size: 35px;
							font-weight: 500;
							color: #fff;
							letter-spacing: 2.2px;
						}
					}
				}
			}

			.button-holder {
				margin-top: 80px;
				text-align: center;

				.section-btn {
					background-color: #eda228;
					color: #fff;
					font-size: 25px;
					padding: 10px 25px;
					display: flex;
					justify-content: center;
					align-items: center;
					-webkit-transition: all 0.3s;
					-moz-transition: all 0.3s;
					transition: all 0.3s;
					font-weight: 400;
					min-width: 220px;
					max-width: 220px;
					margin: 0 auto;

					&:hover {
						background-color: #f4b959;
					}
				}
			}
		}
	}

	.nav-filter-mobile {
		display: none;
	}

	.section-three {
		margin-bottom: 100px;

		.hr-line {
			width: 100%;
			height: 1px;
			background: #ccc;
			display: block;
			margin: 70px 0;
		}

		.titles-holder {
			.section-title {
				font-size: 52px;
				text-align: center;
				font-weight: 500;
				letter-spacing: 1.2px;

				.element-typed {
					margin-left: 20px;
					color: #f4aa2f;
				}
			}
		}
	}

	.section-five {
		background-color: #1c1c1c;
		padding: 80px 0;

		.image-holder {
			text-align: center;
			margin-bottom: 50px;
		}

		.text-holder {
			text-align: center;
			margin-bottom: 50px;

			p {
				font-size: 28px;
				font-weight: 300;
				color: #fff;
				max-width: 770px;
				margin: 0 auto;
				width: 100%;
				line-height: 120%;
			}
		}

		.subscribe-holder {
			max-width: 380px;
			margin: 0 auto;

			.input-group {
				border-bottom: 1px solid #6b6b6b;
				padding-bottom: 5px;

				input {
					border-left: 0;
					border-radius: 0;
					background-color: transparent;
					padding-left: 10px;
					border: 0;
					padding-bottom: 0;
					color: #fff;

					&::placeholder {
						color: #6b6b6b;
					}

					&:focus {
						color: #fff;
						border: 0;
						background: transparent;
						box-shadow: none;
					}
				}

				.input-group-append {
					border-radius: 0;
					border: 0;
					border-right: 0;
					padding: 6px 17px;

					img {
						max-height: 16px;
					}
				}

				.count-me-in {
					border-radius: 0;
					border: 0;
					border-right: 0;
					background: transparent;
					color: #fff;

					img {
						max-width: 30px;
						position: relative;
						top: -3px;
						right: 17px;
					}
				}
			}
		}
	}
}

.inventorypage2-holder {
	header {
		background: #fff;
		position: relative;

		// .header-holder {
		// 	.mobile-holder-flex {
		// 		.header-navigation-menu {
		// 		  .navbar-nav {
		// 		    .nav-link {
		// 		      color: #1c1c1c;

		// 		      &:hover {
		// 		        color: #eda228;
		// 		      }

		// 		      &.join-us-nav-link {
		// 		        color: #fff;
		// 		      }
		// 		    }
		// 		  }
		// 		}
		// 	}
		// }
	}

	.section-one {
		position: relative;

		.section-background-image {
			background-image: url("/img/inv2-hero-image-gradient-v2.png");
			width: 100%;
			min-height: 600px;
			max-height: 600px;
			position: absolute;
			top: 0;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			display: block;
			transform: scale(1.1);
		}

		.text-holder {
			min-height: 600px;
			max-width: 750px;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;

			.titles-holder {
				max-width: 1320px;
				margin-bottom: 10px;

				.filled-text {
					font-size: 52px;
					font-weight: 500;
					letter-spacing: 2.2px;
					padding: 5px 20px;
					width: max-content;
					max-width: 100%;
					margin-bottom: 20px;
					background-image: linear-gradient(to right, #fcb447 50%, #fff 50%),
						linear-gradient(to right, #fcb447 50%, #ffffff 50%);
					transition: background-position 1s;
					background-size: 200% 100%;
					background-repeat: no-repeat;
					background-position: 100% top, 100% top;
					background-clip: text, border-box;

					&.active {
						background-position: 0% top, 0% top;
					}
				}
			}
		}
	}

	.section-two {
		.inventory-menu {
			nav {
				background-color: #31302f;
				margin-top: -69px;

				.navbar-nav {
					text-align: center;
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;

					li {
						font-size: 25px;
						color: #fff;

						a {
							font-size: 25px;
							color: #fff;
							font-weight: 400;

							&::after {
								display: none;
							}
						}

						.dropdown-menu {
							li {
								margin-right: 0;

								a {
									color: #000;
									font-size: 20px;
								}
							}
						}

						&.bold {
							a {
								font-weight: bold;
							}
						}

						&:hover {
							.dropdown-toggle {
								font-weight: bold;
							}
						}
					}
				}
			}
		}
	}

	.nav-filter-mobile {
		display: none;
	}

	.section-three {
		.inventory-items-holder {
			.item-holder {
				max-width: 280px;
				padding: 15px;
				margin-bottom: 80px;

				.thumbnail-holder {
					position: relative;
					cursor: pointer;

					.quick-view {
						display: none;
					}
				}

				.item-title {
					margin-top: 10px;

					h2 {
						font-size: 22px;
						color: #1c1c1c;
						margin-bottom: 5px;
						text-align: left;
					}
				}

				.description-holder {
					p {
						color: #6b6b6b;
						font-size: 15px;
					}
				}

				.price-holder {
					font-size: 14px;
					display: flex;
					align-items: center;

					.bluetext {
						font-size: 15px;
						color: #0d6efd;
						margin-right: 5px;
					}
				}

				&:hover {
					background: #fff;
					-webkit-box-shadow: 0px 0px 9px 0px #ccc;
					box-shadow: 0px 0px 9px 0px #ccc;

					.thumbnail-holder {
						.quick-view {
							position: absolute;
							bottom: 0;
							background: #f1efedc2;
							width: 100%;
							text-align: center;
							min-height: 40px;
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}

					.item-title {
						h2 {
							color: #eda228;
						}
					}
				}
			}
		}

		.loadmore {
			max-width: 240px;
			text-align: center;
			margin: 50px auto;

			.load-more-btn {
				border: 1px solid #ccc;
				padding: 5px 15px;
				display: inline-block;
				font-weight: 500;
				letter-spacing: 2.2px;
				cursor: pointer;

				&:hover {
					border: 1px solid #1c1c1c;
				}
			}

			p {
				font-weight: 400;
				letter-spacing: 1.2px;
				font-size: 11px;
				color: #6b6b6b;
			}
		}
	}

	.filters {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin: 15px 0;
		margin-bottom: 15px;

		.filter1 {
			border: 1px solid #ccc;
			padding: 2px 7px;
		}

		.filter2 {
			border: 1px solid #ccc;
			padding: 2px 7px;
		}

		.select {
			select {
				padding: 3px 10px;
			}
		}
	}

	.breadcrumb {
		margin-bottom: 25px;

		p {
			color: #31302f;

			a {
				color: #31302f;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.hr-line-full {
		margin: 100px 0;
	}

	.section-four {
		padding-bottom: 70px;

		.titles-holder {
			text-align: center;

			.section-title {
				color: #1c1c1c;
				font-size: 52px;
				font-weight: 500;
				letter-spacing: 2.2px;
			}

			.section-subtitle {
				color: #1c1c1c;
				font-size: 28px;
				max-width: 750px;
				font-weight: 400;
				letter-spacing: 1.2px;
				margin: 0 auto;
				margin-bottom: 50px;
			}
		}

		.button-holder {
			text-align: center;

			.section-btn {
				background-color: #eda228;
				color: #fff;
				font-size: 25px;
				padding: 5px 15px;
				display: inline-flex;
				align-items: center;
				-webkit-transition: all 0.3s;
				-moz-transition: all 0.3s;
				transition: all 0.3s;
				font-weight: 400;
				max-width: 235px;

				&:hover {
					background-color: #f4b959;
				}
			}
		}
	}

	.section-five {
		background-color: #1c1c1c;
		padding: 80px 0;

		.image-holder {
			text-align: center;
			margin-bottom: 50px;
		}

		.text-holder {
			text-align: center;
			margin-bottom: 50px;

			p {
				font-size: 28px;
				font-weight: 300;
				color: #fff;
				max-width: 770px;
				margin: 0 auto;
				width: 100%;
				line-height: 120%;
			}
		}

		.subscribe-holder {
			max-width: 380px;
			margin: 0 auto;

			.input-group {
				border-bottom: 1px solid #6b6b6b;
				padding-bottom: 5px;

				input {
					border-left: 0;
					border-radius: 0;
					background-color: transparent;
					padding-left: 10px;
					border: 0;
					padding-bottom: 0;
					color: #fff;

					&::placeholder {
						color: #6b6b6b;
					}

					&:focus {
						color: #fff;
						border: 0;
						background: transparent;
						box-shadow: none;
					}
				}

				.input-group-append {
					border-radius: 0;
					border: 0;
					border-right: 0;
					padding: 6px 17px;

					img {
						max-height: 16px;
					}
				}

				.count-me-in {
					border-radius: 0;
					border: 0;
					border-right: 0;
					background: transparent;
					color: #fff;

					img {
						max-width: 30px;
						position: relative;
						top: -3px;
						right: 17px;
					}
				}
			}
		}
	}
}

.inventorypage3-holder {
	// header {
	background: #fff;
	position: relative;

	// .header-holder {
	// 	.mobile-holder-flex {
	// .header-navigation-menu {
	//   .navbar-nav {
	//     .nav-link {
	//       color: #1c1c1c;

	//       &:hover {
	//         color: #eda228;
	//       }

	//       &.join-us-nav-link {
	//         color: #fff;
	//       }
	//     }
	//   }
	// }
	// 	}
	// }
	// }

	.section-one {
		margin-bottom: 100px;
		position: relative;

		.section-background-image {
			background-image: url("/img/inv3-hero-image-gradient.png");
			width: 100%;
			min-height: 600px;
			max-height: 600px;
			position: absolute;
			top: 0;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: right;
			display: block;
		}

		.text-holder {
			min-height: 600px;
			max-width: 750px;
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;

			.titles-holder {
				max-width: 1320px;
				margin-bottom: 10px;

				.filled-text {
					font-size: 52px;
					font-weight: 500;
					letter-spacing: 2.2px;
					padding: 5px 20px;
					width: max-content;
					max-width: 100%;
					margin-bottom: 20px;
					background-image: linear-gradient(to right, #fcb447 50%, #fff 50%),
						linear-gradient(to right, #fcb447 50%, #ffffff 50%);
					transition: background-position 1s;
					background-size: 200% 100%;
					background-repeat: no-repeat;
					background-position: 100% top, 100% top;
					background-clip: text, border-box;

					&.active {
						background-position: 0% top, 0% top;
					}
				}
			}

			.text-field {
				color: #1c1c1c;
				font-size: 25px;
				font-weight: 400;
				letter-spacing: 1.2px;
			}
		}
	}

	.breadcrumb {
		margin-bottom: 40px;

		p {
			a {
				color: #1c1c1c;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.section-three {
		.vertical-tabs-holder {
			margin-bottom: 120px;

			.leftpill {
				border-left: 3px solid #ffdeaf;
				min-width: 150px;

				.nav-link {
					background: transparent;
					border: 0;
					font-family: "Roboto", sans-serif;
					padding: 0 0 0 24px;
					line-height: 24px;
					position: relative;
					border-radius: 3px;
					cursor: pointer;
					font-size: 22px;
					color: #fcb02aa3;
					letter-spacing: 2.2px;
					text-align: left;
					margin-bottom: 15px;

					&::after {
						content: "";
						width: 3px;
						background-color: #eda228;
						height: 0;
						display: flex;
						position: absolute;
						left: -3px;
						border-radius: 3px;
						bottom: 0;
						transition-property: height;
						transition-duration: 0.3s;
						transition-timing-function: ease-out;
					}

					&.active {
						color: #fcb447;

						&::after {
							height: 100%;
							color: #1c1c1c;
							font-weight: 600;
							top: 0;
							transition-delay: 0.3s;
						}
					}

					&:nth-last-child(1) {
						margin-bottom: 0;
					}
				}
			}

			.tab-content {
				width: 100%;
			}

			.holder {
				.product-title {
					color: #1c1c1c;
					font-weight: 500;
					letter-spacing: 2.2px;
				}

				.description {
					color: #1c1c1c;
					font-size: 20px;
					margin-bottom: 15px;
					font-weight: 400;
					max-width: 380px;
				}

				.price-holder {
					margin-bottom: 20px;

					.suggested {
						color: #6b6b6b;
						font-size: 17px;
						margin-bottom: 5px;

						.line-through {
							text-decoration: line-through;
						}
					}

					.buylow {
						color: #3953a4;
						font-size: 25px;
					}

					.save {
						color: #efa227;
						font-size: 17px;
					}

					.finalprice {
						.firstprice {
							color: #4b9ad4;
							font-weight: 500;
							margin-right: 5px;
						}

						.line-through {
							border-right: 1px solid #1c1c1c;
							padding-right: 10px;
							text-decoration: line-through;
						}

						.save {
							margin-left: 5px;
							color: #1c1c1c;
						}
					}
				}

				.buttons-holder {
					display: flex;
					align-items: center;
					margin-bottom: 40px;

					.button-one {
						display: flex;
						margin-right: 20px;

						a {
							background-color: #eda228;
							color: #fff;
							font-size: 25px;
							padding: 5px 15px;
							display: inline-flex;
							align-items: center;
							-webkit-transition: all 0.3s;
							-moz-transition: all 0.3s;
							transition: all 0.3s;
							font-weight: 500;
							min-width: 225px;
							text-align: center;
							justify-content: center;

							&:hover {
								background-color: #f4b959;
							}
						}
					}

					.button-two {
						a {
							font-size: 25px;
							font-weight: 400;
							color: orange;
							text-decoration: underline;

							&:hover {
								color: #f4b959;
							}
						}
					}
				}

				.specifications-holder {
					.tabs-holder {
						.specif-text {
							p {
								font-size: 17px;
								letter-spacing: 2.2px;
								font-weight: 400;
								text-transform: uppercase;
								margin-bottom: 20px;
								color: #6b6b6b;
							}
						}

						.nav-tabs {
							border-bottom: 3px solid #ffdeaf;
							justify-content: space-between;

							.nav-link {
								background: transparent;
								border: 0;
								min-width: 20%;
								font-family: "Roboto", sans-serif;
								text-align: left;
								padding: 0;
								padding-bottom: 10px;
								color: #ffdeaf;
								padding-right: 40px;

								.name {
									font-size: 22px;
									letter-spacing: 2.2px;
								}

								&.active {
									color: #eda228;

									&::after {
										width: 100%;
										left: 0;
										transition-delay: 0.3s;
									}
								}

								&::after {
									content: "";
									width: 0;
									position: absolute;
									background-color: #eda228;
									height: 3px;
									display: flex;
									bottom: -2px;
									transition-property: width;
									transition-duration: 0.3s;
									transition-timing-function: ease-out;
									right: 0;
								}
							}
						}

						.tab-content {
							padding-top: 30px;
							padding-bottom: 20px;

							.tab-pane {
								.tab-body {
									font-size: 20px;
									max-width: 1000px;
									font-weight: 400;
									color: #1c1c1c;
									text-align: justify;
								}

								&.fade {
									transition: all 0.5s ease-in-out;
									transform: translateX(-50px);
									opacity: 0;

									&.show {
										transition: all 0.3s ease-in-out;
										transform: translateX(0rem);
										opacity: 1;
									}
								}
							}
						}
					}
				}

				.specifications-holder-mobile {
					display: none;
				}

				.icons-holder {
					max-width: 425px;
					margin-top: 20px;

					.holder {
						text-align: center;

						img {
							max-width: 35px;
						}

						p {
							color: #1c1c1c;
							font-size: 14px;
							margin-top: 10px;
						}
					}
				}
			}

			.gallery-holder {
				display: flex;

				.mini-photos-gallery-box {
					max-height: 463px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-direction: column;
					margin-right: 30px;
				}
			}
		}
	}

	.section-six {
		.inventory-items-holder {
			.title {
				color: #1c1c1c;
				font-weight: 500;
				font-size: 2rem;
				text-align: center;
				margin-bottom: 60px;
			}

			.title-mobile {
				display: none;
			}

			.slick-list {
				padding: 5px 9px;

				.item-holder {
					max-width: 280px;
					padding: 15px;
					margin-bottom: 80px;

					.thumbnail-holder {
						position: relative;
						cursor: pointer;

						.quick-view {
							display: none;
						}
					}

					.item-title {
						margin-top: 10px;

						h2 {
							font-size: 22px;
							color: #1c1c1c;
							margin-bottom: 5px;
							text-align: left;
						}
					}

					.description-holder {
						p {
							color: #6b6b6b;
							font-size: 15px;
						}
					}

					.price-holder {
						font-size: 14px;
						display: flex;
						align-items: center;

						.bluetext {
							font-size: 15px;
							color: #0d6efd;
							margin-right: 5px;
						}
					}

					&:hover {
						background: #fff;
						-webkit-box-shadow: 0px 0px 9px 0px #ccc;
						box-shadow: 0px 0px 9px 0px #ccc;

						.thumbnail-holder {
							.quick-view {
								position: absolute;
								bottom: 0;
								background: #f1efedc2;
								width: 100%;
								text-align: center;
								min-height: 40px;
								display: flex;
								align-items: center;
								justify-content: center;
							}
						}

						.item-title {
							h2 {
								color: #eda228;
							}
						}
					}
				}
			}

			.slick-dots {
				display: flex;
				align-items: center;
				list-style: none;
				justify-content: center;
				width: 100%;
				margin-bottom: 40px;

				li {
					margin-right: 20px;

					button {
						border: 0;
						font-size: 0;
						width: 11px;
						height: 11px;
						background: #cccccb;
						border-radius: 50%;
						&::before {
							content: "";
						}
					}

					&.slick-active {
						button {
							background: #9d9b9b;
						}
					}

					&:nth-last-child(1) {
						margin-right: 0;
					}
				}
			}
		}

		.loadmore {
			max-width: 240px;
			text-align: center;
			margin: 0 auto;

			.load-more-btn {
				border: 1px solid #ccc;
				//padding: 5px 15px;
				display: inline-block;
				font-weight: 500;
				letter-spacing: 2.2px;
				cursor: pointer;
				font-size: 25px;
				padding: 10px 25px;

				&:hover {
					border: 1px solid #1c1c1c;
				}
			}

			p {
				font-weight: 400;
				letter-spacing: 2.2px;
				font-size: 20px;
				color: #6b6b6b;
			}
		}
	}

	.hr-line-full2 {
		margin: 100px 0;
	}

	.section-four {
		padding-bottom: 100px;

		.titles-holder {
			text-align: center;

			.section-title {
				color: #1c1c1c;
				font-size: 52px;
				font-weight: 500;
				letter-spacing: 2.2px;
			}

			.section-subtitle {
				color: #1c1c1c;
				font-size: 28px;
				font-weight: 400;
				letter-spacing: 1.2px;
				max-width: 750px;
				margin: 0 auto;
				margin-bottom: 50px;
			}
		}

		.button-holder {
			text-align: center;

			.section-btn {
				background-color: #eda228;
				color: #fff;
				font-size: 25px;
				padding: 10px 25px;
				display: inline-flex;
				align-items: center;
				-webkit-transition: all 0.3s;
				-moz-transition: all 0.3s;
				transition: all 0.3s;
				font-weight: 400;

				&:hover {
					background-color: #f4b959;
				}
			}
		}
	}

	.section-five {
		background-color: #1c1c1c;
		padding: 80px 0;

		.image-holder {
			text-align: center;
			margin-bottom: 50px;
		}

		.text-holder {
			text-align: center;
			margin-bottom: 50px;

			p {
				font-size: 28px;
				font-weight: 300;
				color: #fff;
				max-width: 770px;
				margin: 0 auto;
				width: 100%;
				line-height: 120%;
			}
		}

		.subscribe-holder {
			max-width: 380px;
			margin: 0 auto;

			.input-group {
				border-bottom: 1px solid #6b6b6b;
				padding-bottom: 5px;

				input {
					border-left: 0;
					border-radius: 0;
					background-color: transparent;
					padding-left: 10px;
					border: 0;
					padding-bottom: 0;
					color: #fff;

					&::placeholder {
						color: #6b6b6b;
					}

					&:focus {
						color: #fff;
						border: 0;
						background: transparent;
						box-shadow: none;
					}
				}

				.input-group-append {
					border-radius: 0;
					border: 0;
					border-right: 0;
					padding: 6px 17px;

					img {
						max-height: 16px;
					}
				}

				.count-me-in {
					border-radius: 0;
					border: 0;
					border-right: 0;
					background: transparent;
					color: #fff;

					img {
						max-width: 30px;
						position: relative;
						top: -3px;
						right: 17px;
					}
				}
			}
		}
	}
}

.resourcespage-holder {
	.hr-line-full {
		background-color: #6c6a66;
		width: 100%;
		height: 1px;
		display: block;
	}

	header {
		background: #fff;
		position: relative;

		// .header-holder {
		// 	.mobile-holder-flex {
		// 		.header-navigation-menu {
		// 		  .navbar-nav {
		// 		    .nav-link {
		// 		      color: #1c1c1c;

		// 		      &:hover {
		// 		        color: #eda228;
		// 		      }

		// 		      &.join-us-nav-link {
		// 		        color: #fff;
		// 		      }
		// 		    }
		// 		  }
		// 		}
		// 	}
		// }
	}

	.section-one {
		margin: 200px 0 100px 0;

		.title-holder {
			max-width: 1320px;
			margin: 0 auto;
			margin-bottom: 10px;

			.filled-text {
				font-size: 52px;
				font-weight: 500;
				letter-spacing: 2.2px;
				padding: 5px 20px;
				width: max-content;
				max-width: 100%;
				margin-bottom: 20px;
				background-image: linear-gradient(to right, #fcb447 50%, #fff 50%),
					linear-gradient(to right, #fcb447 50%, #ffffff 50%);
				transition: background-position 1s;
				background-size: 200% 100%;
				background-repeat: no-repeat;
				background-position: 100% top, 100% top;
				background-clip: text, border-box;

				&.active {
					background-position: 0% top, 0% top;

					&.second {
						&::before {
							background-position: 0% top, 0% top;
						}
					}
				}

				&.second {
					margin-left: -30px;
					position: relative;
					// background: transparent;

					// &::before {
					// 	content: "";
					// 	width: max-content;
					// 	left: -1500px;
					// 	background-image: linear-gradient(to right, lightblue 50%, blue 50%),
					// 		linear-gradient(to right, #fcb447 50%, #ffffff 50%);
					// 	transition: background-position 1s;
					// 	background-size: 200% 100%;
					// 	background-repeat: no-repeat;
					// 	background-position: 100% top, 100% top;
					// 	background-clip: text, border-box;

					// 	top: 0;
					// 	display: block;
					// 	height: 88px;
					// 	position: absolute;
					// 	z-index: -1;
					// 	overflow: hidden;
					// }
				}

				&.third {
					margin-left: 90px;
				}
			}
		}
	}

	.section-two {
		background: #f3f3f3;
		padding: 100px 0;

		.firstCol {
			.titles-holder {
				.section-title {
					font-size: 52px;
					font-weight: 500;
					color: #1c1c1c;
					letter-spacing: 2.2px;
				}

				.section-subtitle {
					font-size: 25px;
					letter-spacing: 1.2px;
					font-weight: 400;
					color: #1c1c1c;
					line-height: 140%;
					max-width: 410px;
					text-align: justify;
				}
			}
		}

		.secondCol {
			.holder {
				display: flex;
				min-height: 360px;

				.leftside {
					min-width: 40%;
					max-width: 40%;
					text-align: center;
				}

				.rightside {
					.text-holder {
						margin-left: 40px;

						.eebook-holder {
							color: #1c1c1c;
							font-size: 20px;
							font-weight: 400;
							letter-spacing: 2.2px;
							margin-bottom: 20px;
						}

						.pdf {
							img {
								max-width: 36px;
							}
						}

						.box-title {
							font-size: 22px;
							font-weight: 400;
							letter-spacing: 2.2px;
							color: #1c1c1c;
						}

						.box-subititle {
							font-size: 20px;
							font-weight: 300;
							letter-spacing: 2.2px;
							color: #1c1c1c;
							margin-bottom: 20px;
						}

						.author-date-info {
							margin-bottom: 20px;

							.author {
								color: #1c1c1c;
								font-size: 17px;
								letter-spacing: 2.2px;
								font-weight: 400;
							}

							.date {
								color: #1c1c1c;
								font-size: 17px;
								letter-spacing: 2.2px;
								font-weight: 400;
							}
						}

						.button-holder {
							.section-btn {
								background-color: #eda228;
								color: #fff;
								font-size: 25px;
								padding: 5px 15px;
								display: inline-flex;
								align-items: center;
								-webkit-transition: all 0.3s;
								-moz-transition: all 0.3s;
								transition: all 0.3s;
								font-weight: 500;
								max-width: 235px;

								&:hover {
									background-color: #f4b959;
								}
							}

							.all-res {
								font-size: 25px;
								color: #eda228;
								font-weight: 400;
								margin-left: 30px;
								text-decoration: underline;
								text-underline-offset: 3px;

								&:hover {
									color: #f4b959;
								}
							}
						}
					}
				}
			}
		}

		.download-button-holder {
			margin: 120px auto 100px auto;
			text-align: center;

			.section-btn {
				font-size: 33px;
				color: #fff;
				background: #efa227;
				padding: 10px 25px;
			}
		}
	}

	.section-three {
		.titles-holder {
			padding-top: 100px;
			margin-bottom: 100px;
			text-align: center;

			.minititle {
				font-size: 20px;
				color: #6b6b6b;
				text-align: left;
				font-weight: 400;
				margin-bottom: 40px;
				letter-spacing: 2.2px;
			}

			.section-title {
				font-size: 52px;
				font-weight: 500;
				letter-spacing: 2.2px;
				color: #1c1c1c;
				text-align: left;
				max-width: 1200px;
				width: 100%;
			}
		}
	}

	.posts-holder {
		margin-bottom: 120px;
		padding-top: 120px;

		.team-member-holder {
			position: relative;
			z-index: 1;
			max-width: 380px;
			margin-bottom: 200px;

			.image-holder {
				position: relative;
				margin-top: 13px;
				z-index: 10;

				img {
					width: 380px;
					height: 260px;
				}

				.learn-more-holder {
					position: absolute;
					bottom: 0;
					background: #f1efedc2;
					width: 100%;
					text-align: center;
					min-height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					transition: all 0.3s;
					opacity: 0;
				}
			}

			.member-info {
				.name {
					font-size: 25px;
					letter-spacing: 1.2px;
					font-weight: 500;
					padding-top: 30px;
					color: #1c1c1c;
				}

				.position {
					color: #6b6b6b;
					font-size: 15px;
					letter-spacing: 1.2px;
					font-weight: 500;
					text-transform: uppercase;
				}
			}

			.member-description {
				background: #fff;
				box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
				position: absolute;
				top: -40px;
				left: -40px;
				right: -40px;
				padding: 40px;
				z-index: 5;
				opacity: 1;
				pointer-events: none;
				transition: opacity 500ms ease-out;

				.name {
					color: #eda228;
					font-size: 25px;
					letter-spacing: 1.2px;
					font-weight: 500;
					padding-top: 30px;
				}

				.position {
					color: #6b6b6b;
					font-size: 15px;
					letter-spacing: 1.2px;
					font-weight: 500;
					text-transform: uppercase;
				}

				.description {
					color: #1c1c1c;
					font-size: 18px;
					letter-spacing: 1.2px;
					font-weight: 400;
					padding-top: 30px;
				}
			}

			&:hover {
				z-index: 10;
				transition: opacity 500ms ease-in;

				.image-holder {
					.learn-more-holder {
						position: absolute;
						bottom: 0;
						background: #f1efedc2;
						width: 100%;
						text-align: center;
						min-height: 40px;
						display: flex;
						align-items: center;
						justify-content: center;
						transition: all 1s;
						opacity: 1;

						a {
							font-size: 22px;
							color: #1c1c1c;
						}
					}
				}

				.member-description {
					opacity: 1;
					pointer-events: auto;
				}
			}
		}
	}

	.section-four {
		padding: 70px 0;

		.titles-holder {
			text-align: center;

			.section-title {
				color: #1c1c1c;
				font-size: 52px;
				margin-bottom: 15px;
				letter-spacing: 2.2px;
				font-weight: 500;
			}

			.section-subtitle {
				color: #1c1c1c;
				font-size: 25px;
				max-width: 800px;
				font-weight: 400;
				margin: 0 auto;
				letter-spacing: 1.2px;
				margin-bottom: 50px;
				text-align: center;
			}
		}

		.button-holder {
			text-align: center;

			.section-btn {
				background-color: #eda228;
				color: #fff;
				font-size: 25px;
				padding: 10px 25px;
				display: inline-flex;
				align-items: center;
				-webkit-transition: all 0.3s;
				-moz-transition: all 0.3s;
				transition: all 0.3s;
				font-weight: 400;

				&:hover {
					background: #f4b959;
				}
			}
		}
	}

	.section-five {
		background-color: #1c1c1c;
		padding: 80px 0;

		.image-holder {
			text-align: center;
			margin-bottom: 50px;
		}

		.text-holder {
			text-align: center;
			margin-bottom: 50px;

			p {
				font-size: 28px;
				font-weight: 300;
				color: #fff;
				max-width: 770px;
				margin: 0 auto;
				width: 100%;
				line-height: 120%;
			}
		}

		.subscribe-holder {
			max-width: 380px;
			margin: 0 auto;

			.input-group {
				border-bottom: 1px solid #6b6b6b;
				padding-bottom: 5px;

				input {
					border-left: 0;
					border-radius: 0;
					background-color: transparent;
					padding-left: 10px;
					border: 0;
					padding-bottom: 0;
					color: #fff;

					&::placeholder {
						color: #6b6b6b;
					}

					&:focus {
						color: #fff;
						border: 0;
						background: transparent;
						box-shadow: none;
					}
				}

				.input-group-append {
					border-radius: 0;
					border: 0;
					border-right: 0;
					padding: 6px 17px;

					img {
						max-height: 16px;
					}
				}

				.count-me-in {
					border-radius: 0;
					border: 0;
					border-right: 0;
					background: transparent;
					color: #fff;

					img {
						max-width: 30px;
						position: relative;
						top: -3px;
						right: 17px;
					}
				}
			}
		}
	}

	.section-six {
		background-color: #f6f6f6;
		padding: 50px 0;

		.titles-holder {
			text-align: center;
			margin-bottom: 80px;

			.section-title {
				font-size: 61px;
				color: #000;
			}

			.section-subtitle {
				font-size: 55px;
				color: #000;
			}
		}

		.post-holder {
			box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
			max-width: 380px;
			padding-bottom: 30px;

			.image-holder {
				min-height: 215px;
			}

			.text-holder {
				padding: 20px 34px 50px 20px;

				.title {
					font-size: 35px;
					color: #000;
					font-weight: bold;
				}

				.text {
					font-size: 20px;
					font-weight: 300;
				}
			}

			.button-holder {
				text-align: right;
				margin: 0 30px;

				.learn-now {
					color: #efa227;
					font-size: 23px;
					transition: transform 0.25s ease-in-out;
					display: block;

					.arrow-right {
						margin-left: 12px;

						img {
							transition: transform 0.25s ease-in-out;
						}
					}

					&:hover {
						transform: scale(1.1);

						.arrow-right {
							img {
								transform: translatex(10px);
								transition: transform 0.25s ease;
							}
						}
					}
				}
			}
		}
	}

	.section-seven {
		padding: 50px 0;

		.post-holder {
			box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
			max-width: 380px;
			padding-bottom: 30px;

			.image-holder {
				min-height: 215px;
			}

			.text-holder {
				padding: 20px 34px 50px 20px;

				.title {
					font-size: 35px;
					color: #000;
					font-weight: bold;
				}

				.text {
					font-size: 20px;
					font-weight: 300;
				}
			}

			.button-holder {
				text-align: right;
				margin: 0 30px;

				.learn-now {
					color: #efa227;
					font-size: 23px;
					transition: transform 0.25s ease-in-out;
					display: block;

					.arrow-right {
						margin-left: 12px;

						img {
							transition: transform 0.25s ease-in-out;
						}
					}

					&:hover {
						transform: scale(1.1);

						.arrow-right {
							img {
								transform: translatex(10px);
								transition: transform 0.25s ease;
							}
						}
					}
				}
			}
		}
	}
}

.blogpage-holder {
	header {
		background: #fff;
		position: relative;

		// .header-holder {
		// 	.mobile-holder-flex {
		// 		.header-navigation-menu {
		// 		  .navbar-nav {
		// 		    .nav-link {
		// 		      color: #1c1c1c;

		// 		      &:hover {
		// 		        color: #eda228;
		// 		      }

		// 		      &.join-us-nav-link {
		// 		        color: #fff;
		// 		      }
		// 		    }
		// 		  }
		// 		}
		// 	}
		// }
	}

	.section-one {
		margin-bottom: 80px;
		position: relative;
		min-height: 600px;
		position: relative;

		.section-background-image {
			background-image: url("/img/blogpage-hero.png");
			width: 100%;
			min-height: 600px;
			max-height: 600px;
			position: absolute;
			top: 0;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			display: block;
		}
	}

	.blog-content {
		margin-bottom: 80px;

		.topside {
			.title-holder {
				margin-bottom: 80px;

				h1 {
					color: #1c1c1c;
					font-weight: 500;
					font-size: 52px;
					letter-spacing: 2.2px;
				}

				.subtitle {
					font-size: 25px;
					color: #1c1c1c;
					font-weight: 400;
					letter-spacing: 1.2px;
				}

				.author-holder {
					display: flex;
					align-items: center;
					margin-top: 20px;

					img {
						border-radius: 50px;
						margin-right: 10px;
					}

					.author-text-holder {
						display: flex;
						align-items: center;

						.author-name {
							font-size: 20px;
							color: #1c1c1c;
							font-weight: 400;
							letter-spacing: 1.2px;
							margin-right: 25px;
						}

						.readtime {
							font-size: 20px;
							color: #1c1c1c;
							font-weight: 400;
							letter-spacing: 1.2px;
						}
					}
				}
			}
		}

		.leftside {
			padding-right: 100px;

			.post-content {
				.content-title {
					color: #1c1c1c;
					margin-top: 15px;
					margin-bottom: 15px;
					letter-spacing: 2.2px;
				}

				p {
					margin-bottom: 15px;
					text-align: justify;
				}

				ul {
					padding-left: 80px;
					padding-top: 10px;
					padding-bottom: 10px;
				}

				li {
					padding-top: 5px;
					padding-bottom: 5px;
				}
			}

			.share-this-article {
				p {
					margin-bottom: 20px;
				}

				.holder {
					display: flex;
					align-items: center;

					a {
						border: 1px solid #ccc;
						width: 40px;
						height: 40px;
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 50px;
						margin-right: 20px;

						i {
							color: #1c1c1c;
							font-size: 20px;
						}

						&:hover {
							border: 1px solid #1c1c1c;
						}
					}
				}
			}
		}

		.rightside {
			.box {
				margin-bottom: 40px;

				h4 {
					color: #f29e0d;
				}

				p {
					color: #1c1c1c;
					text-align: justify;
				}

				.input-holder {
					display: flex;
					align-items: center;
					margin-top: 15px;

					input {
						min-height: 50px;
						border: 1px solid #1c1c1c;
						border-right: 0;
						padding-left: 10px;
					}

					button {
						background-color: #eda228;
						border: 0;
						min-height: 50px;
						min-width: 120px;
						color: #fff;
						font-weight: 400;
						font-size: 25px;

						&:hover {
							background-color: #f4b959;
						}
					}
				}
			}
		}
	}

	.explore-more-topics {
		background: #f3f2f2;
		padding-top: 120px;
		padding-bottom: 80px;

		.section-title {
			text-align: center;
			color: #1c1c1c;
			margin-bottom: 60px;
		}

		.team-member-holder {
			position: relative;
			z-index: 1;
			max-width: 360px;
			margin-bottom: 200px;
			margin: 0 auto 200px auto;
			margin-top: 40px;

			.image-holder {
				position: relative;
				z-index: 10;

				img {
					width: 360px;
					height: 260px;
				}

				.learn-more-holder {
					display: none;
				}
			}

			.member-info {
				.name {
					font-size: 25px;
					letter-spacing: 1.2px;
					font-weight: 500;
					padding-top: 30px;
					color: #1c1c1c;
				}

				.position {
					color: #6b6b6b;
					font-size: 15px;
					letter-spacing: 1.2px;
					font-weight: 500;
					text-transform: uppercase;
				}
			}

			.member-description {
				background: #fff;
				box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
				position: absolute;
				top: -40px;
				left: -40px;
				right: -40px;
				padding: 40px;
				z-index: 5;
				opacity: 0;
				pointer-events: none;
				transition: opacity 500ms ease-out;

				.name {
					color: #eda228;
					font-size: 25px;
					letter-spacing: 1.2px;
					font-weight: 500;
					padding-top: 30px;
				}

				.position {
					color: #6b6b6b;
					font-size: 15px;
					letter-spacing: 1.2px;
					font-weight: 500;
					text-transform: uppercase;
				}

				.description {
					color: #1c1c1c;
					font-size: 18px;
					letter-spacing: 1.2px;
					font-weight: 400;
					padding-top: 30px;
					text-align: justify;
				}
			}

			&:hover {
				z-index: 10;
				transition: opacity 500ms ease-in;

				.image-holder {
					.learn-more-holder {
						position: absolute;
						bottom: 0;
						background: #f1efedc2;
						width: 100%;
						text-align: center;
						min-height: 40px;
						display: flex;
						align-items: center;
						justify-content: center;

						a {
							font-size: 22px;
							color: #1c1c1c;
						}
					}
				}

				.member-description {
					opacity: 1;
					pointer-events: auto;
				}
			}
		}

		.slick-list {
			height: 430px;
		}

		.slick-dots {
			display: flex;
			align-items: center;
			list-style: none;
			justify-content: center;
			width: 100%;
			margin-top: 40px;
			margin-bottom: 40px;

			li {
				margin-right: 20px;

				button {
					border: 0;
					font-size: 0;
					width: 11px;
					height: 11px;
					background: #cccccb;
					border-radius: 50%;
					&::before {
						content: "";
					}
				}

				&.slick-active {
					button {
						background: #9d9b9b;
					}
				}

				&:nth-last-child(1) {
					margin-right: 0;
				}
			}
		}

		.loadmore {
			max-width: 240px;
			text-align: center;
			margin: 50px auto;

			.load-more-btn {
				border: 1px solid #ccc;
				padding: 5px 15px;
				display: inline-block;
				font-weight: 500;
				letter-spacing: 2.2px;
				cursor: pointer;

				&:hover {
					border: 1px solid #1c1c1c;
				}
			}
		}
	}

	.section-nine {
		padding: 70px 0;

		.titles-holder {
			text-align: center;

			.section-title {
				color: #1c1c1c;
				font-size: 52px;
				margin-bottom: 15px;
				letter-spacing: 2.2px;
				font-weight: 500;
			}

			.section-subtitle {
				color: #1c1c1c;
				font-size: 28px;
				max-width: 800px;
				font-weight: 400;
				margin: 0 auto;
				letter-spacing: 1.2px;
				margin-bottom: 50px;
				text-align: center;
			}
		}

		.button-holder {
			text-align: center;

			.section-btn {
				background-color: #eda228;
				color: #fff;
				font-size: 25px;
				padding: 10px 25px;
				display: inline-flex;
				align-items: center;
				-webkit-transition: all 0.3s;
				-moz-transition: all 0.3s;
				transition: all 0.3s;
				font-weight: 400;
				max-width: 185px;
				min-width: 185px;

				&:hover {
					background: #f4b959;
				}
			}
		}
	}

	// .section-ten {
	//   background-color: #1c1c1c;
	//   padding: 80px 0;

	//   .image-holder {
	//     text-align: center;
	//     margin-bottom: 50px;
	//   }

	//   .text-holder {
	//     text-align: center;
	//     margin-bottom: 50px;

	//     p {
	//       font-size: 28px;
	//       font-weight: 300;
	//       color: #fff;
	//       max-width: 770px;
	//       margin: 0 auto;
	//       width: 100%;
	//       line-height: 120%;
	//     }
	//   }

	//   .subscribe-holder {
	//     max-width: 380px;
	//     margin: 0 auto;

	//     .input-group {
	//       border-bottom: 1px solid #6b6b6b;
	//       padding-bottom: 5px;

	//       input {
	//         border-left: 0;
	//         border-radius: 0;
	//         background-color: transparent;
	//         padding-left: 10px;
	//         border: 0;
	//         padding-bottom: 0;
	//         color: #fff;

	//         &::placeholder {
	//           color: #6b6b6b;
	//         }

	//         &:focus {
	//           color: #fff;
	//           border: 0;
	//           background: transparent;
	//           box-shadow: none;
	//         }
	//       }

	//       .input-group-append {
	//         border-radius: 0;
	//         border: 0;
	//         border-right: 0;
	//         padding: 6px 17px;

	//         img {
	//           max-height: 16px;
	//         }
	//       }

	//       .count-me-in {
	//         border-radius: 0;
	//         border: 0;
	//         border-right: 0;
	//         background: transparent;
	//         color: #fff;

	//         img {
	//           max-width: 30px;
	//           position: relative;
	//           top: -3px;
	//           right: 17px;
	//         }
	//       }
	//     }
	//   }
	// }

	.whatisaresllerlicense {
		min-height: auto !important;

		//.section-background-image {
		//
		//  background-image: url('../assets/img/rl-new.png');
		//  width: 100%;
		//  min-height: 600px;
		//  max-height: 600px;
		//  position: absolute;
		//  top: 0;
		//  background-size: cover;
		//  background-repeat: no-repeat;
		//  background-position: center;
		//  display: block;
		//}
	}

	.risksofpurchasinhwithoutlicense {
		min-height: auto !important;

		//.section-background-image {
		//
		//  background-image: url('../assets/img/blogpages/risksofpurchasingwithout.jpeg');
		//  width: 100%;
		//  min-height: 600px;
		//  max-height: 600px;
		//  position: absolute;
		//  top: 0;
		//  background-size: cover;
		//  background-repeat: no-repeat;
		//  background-position: center;
		//  display: block;
		//}
	}

	.manifested {
		.section-background-image {
			background-image: url("/img/blogpages/manifested.jpeg");
			width: 100%;
			min-height: 600px;
			max-height: 600px;
			position: absolute;
			top: 0;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			display: block;
		}
	}

	.howtolegallystartyourbusiness {
		.section-background-image {
			background-image: url("/img/blogpages/howtolegallystartyourbusiness.jpeg");
			width: 100%;
			min-height: 600px;
			max-height: 600px;
			position: absolute;
			top: 0;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			display: block;
		}
	}

	.howtoavoidbeingscammed {
		display: flex;
		align-items: center;
		justify-content: center;

		height: max-content;

		background-color: #202221;

		.section-background-image {
			background-image: url("/img/blogpages/scammed.jpeg");
			width: 100%;
			max-width: 1296px;
			min-height: 600px;
			max-height: 600px;
			position: absolute;
			top: 0;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			display: block;
		}
	}

	@media (max-width: 850px) {
		.howtoavoidbeingscammed {
			.section-background-image {
				min-height: 450px !important;
				max-height: 450px !important;
			}
		}
	}

	@media (max-width: 650px) {
		.howtoavoidbeingscammed {
			.section-background-image {
				margin-top: 3rem;
				min-height: 350px !important;
				max-height: 350px !important;
			}
		}
	}

	@media (max-width: 450px) {
		.howtoavoidbeingscammed {
			.section-background-image {
				margin-top: 3rem;
				min-height: 250px !important;
				max-height: 250px !important;
			}
		}
	}

	@media (max-width: 400px) {
		.howtoavoidbeingscammed {
			.section-background-image {
				margin-top: 5rem;
				min-height: 230px !important;
				max-height: 230px !important;
			}
		}
	}

	.avoidrisk {
		.section-background-image {
			background-image: url("/img/blogpages/avoidrisk.jpeg");
			width: 100%;
			min-height: 600px;
			max-height: 600px;
			position: absolute;
			top: 0;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			display: block;
		}
	}

	.avoidoverextending {
		min-height: auto !important;

		//.section-background-image {
		//
		//  background-image: url('../assets/img/blogpages/avoidoverextending.jpeg');
		//  width: 100%;
		//  min-height: 600px;
		//  max-height: 600px;
		//  position: absolute;
		//  top: 0;
		//  background-size: cover;
		//  background-repeat: no-repeat;
		//  background-position: center;
		//  display: block;
		//}
	}

	.whatsthebesttrucktobuy {
		.section-background-image {
			// background-image: url("/img/blogpages/whatsthebesttrucktobuy.jpeg");
			width: 100%;
			min-height: 600px;
			max-height: 600px;
			position: absolute;
			top: 0;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			display: block;
		}
	}

	.howtorecievetruckload {
		.section-background-image {
			background-image: url("/img/blogpages/howtorecieve.jpeg");
			width: 100%;
			min-height: 600px;
			max-height: 600px;
			position: absolute;
			top: 0;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			display: block;
		}
	}
}

.downloadForm {
	.input-group {
		input {
			min-height: 45px;
			&::placeholder {
				color: #6c757d;
			}
		}
		button {
			font-size: 22px !important;
			border: none;
			width: 100%;
			max-width: 100% !important;
			text-align: center;
			justify-content: center;
		}
	}
}

//media

@media (max-width: 1600px) {
	.homepage-holder {
		.section-one {
			video {
				height: 1100px !important;
			}
		}
	}

	.resourcespage-holder {
		.posts-holder {
			.team-member-holder {
				max-width: 340px;
			}

			.col-lg-4 {
				display: flex;
				justify-content: center;
			}
		}
	}

	.inventorypage2-holder {
		.section-three {
			.inventory-items-holder {
				.col-lg-4 {
					display: flex;
					justify-content: flex-start;
				}
			}
		}
	}
}

@media (max-width: 1400px) {
	.about-holder {
		.section-one {
			.section-background-image {
				background-size: cover;
			}
		}
	}
}

@media (max-width: 1200px) {
	.homepage-holder {
		.section-one {
			.text-holder {
				h1 {
					font-size: 44px;
					text-align: center;
				}

				h4 {
					font-size: 28px;
					text-align: center;
				}
			}
		}

		.section-eight {
			.slider-holder {
				.firstrtl {
					.slider-for {
						.slide {
							.text-holder {
								max-width: 480px;
							}
						}
					}
				}
			}
		}
	}

	.about-holder {
		.section-six {
			margin-top: 0;
			margin-bottom: 60px;

			.section-background-image {
				position: relative;
				width: 100%;
				min-height: 400px;
				max-height: 400px;
			}

			.firstCol {
				padding: 0;
				margin-left: 0;
				width: 100%;

				.titles-holder {
					padding-top: 60px;

					.section-title {
						text-align: center;
					}
				}
			}
		}
	}
}

@media (max-width: 991px) {
	.homepage-holder {
		.section-one {
			min-height: 600px;

			video {
				height: 680px !important;
			}

			.section-background-image-overlay {
				min-height: 680px;
				max-height: 680px;
			}

			.text-holder {
				min-height: 600px;
				margin-top: 80px;
			}
		}
		.section-two .desktop-wrapper {
			display: none;
		}

		.section-two {
			.boxes-holder {
				.col-lg-4 {
					margin-bottom: 40px;

					&:nth-last-child(1) {
						margin-bottom: 0;
					}
				}
			}
		}

		.section-seven {
			.content-holder {
				.list-holder {
					.glove-title {
						font-size: 80px;
					}

					.list-item {
						font-size: 36px;

						.zero {
							font-size: 36px;
						}
					}
				}
			}
		}

		.section-four {
			min-height: 490px;

			.section-background-image {
				min-height: 800px;
				max-height: 800px;
			}

			.text-holder {
				min-height: 490px;

				h2 {
					font-size: 60px;
				}
			}
		}
	}

	.about-holder {
		.section-one {
			min-height: 400px;

			.section-background-image {
				min-height: 400px;
				max-height: 400px;
				background-size: cover;
			}

			.text-holder {
				min-height: 400px;

				.titles-holder {
					span {
						font-size: 34px;
					}
				}
			}
		}

		.section-two {
			.mission-container {
				flex-direction: column;
			}
			.mission-container .description {
				padding-left: 0;
			}
			.title-holder {
				.filled-text {
					font-size: 42px;
					margin-bottom: 0;

					&.second {
						margin-bottom: 10px;

						&::before {
							height: 82px;
						}
					}
				}
			}
		}

		.vertical-tabs-holder {
			.nav {
				max-width: 180px;

				.nav-link {
					padding: 24px 0px 24px 10px;
					font-size: 21px;
					line-height: 31px;
				}
			}

			.tab-content {
				.tab-pane {
					padding: 30px 20px;

					p {
						font-size: 21px;
					}
				}
			}
		}
	}

	.faqpage-holder {
		.section-one {
			.title-holder {
				.filled-text {
					font-size: 42px;
					margin-bottom: 0;

					&.second {
						margin-bottom: 10px;

						&::before {
							height: 82px;
						}
					}
				}
			}
		}

		.section-three {
			.vertical-tabs-holder {
				.nav {
					max-width: 180px;

					.nav-link {
						padding: 24px 0px 24px 10px;
						font-size: 21px;
						line-height: 31px;
					}
				}

				.tab-content {
					.tab-pane {
						padding: 0;

						p {
							font-size: 21px;
						}
					}
				}
			}

			.accordion {
				.accordion-item {
					.accordion-body {
						padding-left: 60px;
					}
				}
			}
		}

		.section-six {
			.firstCol {
				.titles-holder {
					margin-top: 0;
					margin-bottom: 60px;

					.section-title {
						font-size: 44px;
					}

					.section-subtitle {
						font-size: 21px;
						max-width: 100%;
					}
				}
			}

			.secondCol {
				.holder {
					.rightside {
						.text-holder {
							.box-title {
								font-size: 19px;
							}

							.box-subititle {
								font-size: 18px;
							}

							.button-holder {
								display: flex;
								flex-direction: column;

								.section-btn {
									margin-bottom: 20px;
								}

								.all-res {
									margin-left: 0;
								}
							}
						}
					}
				}
			}
		}
	}

	.resourcespage-holder {
		.section-one {
			.title-holder {
				.filled-text {
					font-size: 42px;
					margin-bottom: 0;

					&.second {
						margin-bottom: 10px;

						&::before {
							height: 82px;
						}
					}
				}
			}
		}

		.section-two {
			.firstCol {
				.titles-holder {
					margin-top: 0;
					margin-bottom: 60px;

					.section-title {
						font-size: 44px;
					}

					.section-subtitle {
						font-size: 21px;
						max-width: 100%;
					}
				}
			}

			.secondCol {
				.holder {
					.rightside {
						.text-holder {
							.box-title {
								font-size: 19px;
							}

							.box-subititle {
								font-size: 18px;
							}

							.button-holder {
								display: flex;
								flex-direction: column;

								.section-btn {
									margin-bottom: 20px;
								}

								.all-res {
									margin-left: 0;
								}
							}
						}
					}
				}
			}
		}

		.posts-holder {
			.section-title {
				margin-bottom: 30px;
			}

			.team-member-holder {
				max-width: 100%;

				.image-holder {
					img {
						width: 100%;
						height: 260px;
						object-fit: cover;
						padding-bottom: 10px;
					}
				}

				&:hover {
					.image-holder {
						.learn-more-holder {
							bottom: 10px;
						}
					}
				}
			}
		}
	}

	.reviewspage-holder {
		.section-one {
			.titles-holder {
				.filled-text {
					font-size: 42px;
					margin-bottom: 0;

					&.second {
						margin-bottom: 10px;

						&::before {
							height: 82px;
						}
					}
				}
			}
		}
	}

	.blogpage-holder {
		.explore-more-topics {
			.section-title {
				margin-bottom: 30px;
			}

			.team-member-holder {
				max-width: 290px;

				.image-holder {
					img {
						max-height: 200px;
						height: auto;
						padding-bottom: 10px;
						max-width: 96%;
						margin: 0 auto;
					}
				}

				&:hover {
					.image-holder {
						.learn-more-holder {
							bottom: 10px;
							max-width: 96%;
							margin: 0 auto;
							left: 6px;
						}
					}
				}
			}
		}

		.blog-content {
			.leftside {
				padding-right: 0;
				margin-bottom: 60px;
			}
		}
	}

	.contactpage-holder {
		.section-one {
			.title-holder {
				.filled-text {
					font-size: 42px;
					margin-bottom: 30px;

					&.second {
						margin-bottom: 10px;

						&::before {
							height: 82px;
						}
					}
				}

				.text-field {
					font-size: 21px;
				}
			}
		}

		.beer {
			font-family: "Waiting for the Sunrise", cursive;
			font-size: 1em;
		}
	}

	.inventorypage1-holder {
		.section-two {
			.boxes-holder {
				.col-lg-4 {
					margin-bottom: 40px;

					&:nth-last-child(1) {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.inventorypage2-holder {
		.section-one {
			min-height: 400px;
			max-height: 400px;

			.section-background-image {
				min-height: 400px;
				max-height: 400px;
				background-size: cover;
			}

			.text-holder {
				min-height: 400px;
				max-height: 400px;

				.titles-holder {
					.filled-text {
						font-size: 42px;
						margin-bottom: 0;

						&.second {
							margin-bottom: 10px;

							&::before {
								height: 82px;
							}
						}
					}
				}
			}
		}
	}

	.inventorypage3-holder {
		.section-one {
			min-height: 400px;
			max-height: 400px;

			.section-background-image {
				min-height: 400px;
				max-height: 400px;
				background-size: cover;
				background-position: left;
			}

			.text-holder {
				min-height: 400px;
				max-height: 400px;

				.titles-holder {
					.filled-text {
						font-size: 42px;
						margin-bottom: 0;

						&.second {
							margin-bottom: 10px;

							&::before {
								height: 82px;
							}
						}
					}
				}
			}
		}

		.section-three {
			.vertical-tabs-holder {
				.nav {
					flex-direction: row !important;
					display: flex;
					max-width: 100%;
					justify-content: space-between;
					border-left: 0;
					border-bottom: 3px solid #ffdeaf;

					.nav-link {
						margin: 0;
						padding: 10px 15px;

						&::after {
							height: 0;
						}

						&.active {
							&::after {
								border-bottom: 2px solid #fcb447;
								width: 100%;
								bottom: -2px;
								left: 0;
								top: unset;
								height: 0;
							}
						}
					}
				}

				.tab-content {
					padding-top: 50px;
					padding-bottom: 60px;

					.tab-pane {
						padding: 0;

						.image-holder {
							display: flex;
							align-items: center;

							.icons-holder {
								.holder {
									margin-bottom: 30px;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: 992px) {
	header {
		.header-holder {
			.mobile-holder-flex {
				.logo-holder {
					// margin-right: auto;
					cursor: pointer;
				}
			}
		}
	}

	.header-navigation-menu {
		width: 100%;
		position: absolute;
		margin-left: unset;

		.container-holder {
			width: 100%;

			.navbar-toggler {
				position: absolute;
				right: 0;
				top: 0;
				border: 0;

				.navbar-toggler-icon {
					background-image: none;

					.line {
						width: 30px;
						height: 4px;
						background: #ccc;
						margin-bottom: 5px;
						display: block;
					}
				}

				&:focus {
					border: 0;
					box-shadow: none;
				}
			}

			.navbar-collapse {
				position: absolute;
				left: 0px;
				width: 100%;
				top: 60px;
				background: #1c1c1c;

				text-align: center;

				.navbar-nav {
					padding: 60px 0;

					.nav-link {
						margin-right: 0;
						color: #fff;
						margin-bottom: 20px;

						&:nth-last-child(1) {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}

	.homepage-holder {
		.section-one {
			.text-holder {
				h1 {
					font-size: 34px;
				}

				h4 {
					font-size: 22px;
				}
			}
		}

		.section-two {
			.titles-holder {
				.section-title {
					font-size: 44px;
				}
			}

			.boxes-holder {
				.col-lg-4 {
					margin-bottom: 40px;
					display: flex;
					justify-content: center;
					align-items: center;
					align-items: center;
				}
			}
		}

		.section-three {
			.titles-holder {
				margin-bottom: 40px;

				.minititle {
					margin-bottom: 30px;
				}

				.section-title {
					//font-size: 32px
					font-size: 44px;
				}
			}

			.tabs-holder {
				.nav-tabs {
					display: flex;
					justify-content: space-between;
					align-items: center;

					.nav-link {
						max-width: 20%;
						padding: 0;

						.number {
							font-size: 18px;
							margin-bottom: 10px;
						}

						.name {
							font-size: 18px;
							margin-bottom: 10px;
						}
					}
				}

				.tab-content {
					padding-top: 50px;
					padding-bottom: 60px;

					.tab-pane {
						.tab-body {
							font-size: 21px;
						}
					}
				}
			}
		}

		.section-six {
			.secondCol {
				.text-holder {
					padding-left: 0;
					padding-top: 60px;

					.section-title {
						font-size: 44px;
					}

					.section-subtitle {
						font-size: 21px;
					}
				}
			}
		}

		.section-eight {
			.slider-holder {
				.firstrtl {
					.slider-for {
						.slide {
							.text-holder {
								min-height: 554px;
								margin-left: 150px;
								margin-bottom: 70px;
								margin-right: 60px;

								justify-content: flex-end;

								.review-text {
									font-size: 21px;
								}

								.client-info {
									.client-name {
										font-size: 23px;
									}

									.client-website {
										font-size: 23px;
									}
								}
							}
						}
					}
				}

				.secondrtl {
					position: absolute;
					top: 0;
					right: 0;
					left: 0;
					width: 100%;

					.slider-nav {
						width: 80%;
						right: -50px;
						top: 40px;
						text-align: left;
						padding-left: 40px;
						max-width: 60%;
						min-width: 60%;
						min-height: 142px;
						max-height: 142px;

						.slide {
							.text-holder {
								p {
									font-size: 33px;
								}
							}
						}
					}
				}
			}
		}

		.section-nine {
			.titles-holder {
				.section-title {
					font-size: 44px;
				}

				.section-subtitle {
					font-size: 21px;
					margin-bottom: 30px;
				}
			}
		}
	}

	.about-holder {
		.section-two {
			.title-holder {
				.filled-text {
					font-size: 34px;
					margin-bottom: 0;

					&.second {
						margin-bottom: 0px;

						&::before {
							height: 62px;
						}
					}
				}
			}
		}

		.vertical-tabs-holder {
			.title-holder {
				margin-bottom: 40px;

				.minititle {
					margin-bottom: 30px;
				}

				.title {
					font-size: 44px;
				}
			}
		}

		.section-three {
			.titles-holder {
				.minititle {
					margin-bottom: 30px;
				}

				.section-title {
					font-size: 44px;
				}

				.section-subtitle {
					font-size: 21px;
				}
			}
		}

		.what-makes-us-different-tabs {
			.titles-holder {
				margin-bottom: 40px;

				.minititle {
					margin-bottom: 30px;
				}

				.section-title {
					//font-size: 32px
					font-size: 44px;
				}
			}

			.tabs-holder {
				.nav-tabs {
					display: flex;
					justify-content: space-between;
					align-items: center;

					.nav-link {
						max-width: 20%;
						padding: 0;

						.number {
							font-size: 18px;
							margin-bottom: 10px;
						}

						.name {
							font-size: 18px;
							margin-bottom: 10px;
						}
					}
				}

				.tab-content {
					padding-top: 50px;
					padding-bottom: 60px;

					.tab-pane {
						.tab-body {
							font-size: 21px;
						}
					}
				}
			}
		}

		.get-to-know-us {
			.title {
				font-size: 34px;
			}
		}

		.team-members {
			.team-member-holder {
				margin: 0 auto 260px auto;
			}
		}

		.section-six {
			.firstCol {
				.titles-holder {
					.section-title {
						font-size: 44px;
					}

					.section-subtitle {
						font-size: 21px;
					}
				}
			}
		}

		.section-seven {
			.titles-holder {
				.section-title {
					font-size: 44px;
				}

				.section-subtitle {
					font-size: 21px;
					padding: 0 15px;
				}
			}
		}
	}

	.faqpage-holder {
		.section-one {
			.title-holder {
				margin-bottom: 0;

				.filled-text {
					font-size: 34px;
					margin-bottom: 0;

					&.second {
						margin-bottom: 0px;

						&::before {
							height: 62px;
						}
					}
				}
			}
		}

		.section-three {
			.title-holder {
				.minititle {
					font-size: 18px;
				}

				.title {
					font-size: 32px;
				}
			}

			.vertical-tabs-holder {
				.nav {
					flex-direction: row !important;
					display: flex;
					max-width: 100%;
					justify-content: space-between;
					border-left: 0;

					.nav-link {
						padding: 15px 0px 5px 0;

						&::after {
							height: 0;
						}

						&.active {
							&::after {
								border-bottom: 2px solid #fcb447;
								width: 100%;
								bottom: 0;
								left: 0;
								top: unset;
								height: 0;
							}
						}
					}
				}

				.tab-content {
					padding-top: 50px;
					padding-bottom: 60px;

					.tab-pane {
						padding: 0;
					}
				}
			}
		}

		.section-four {
			.titles-holder {
				.section-title {
					font-size: 44px;
				}

				.section-subtitle {
					font-size: 21px;
					padding: 0 15px;
				}
			}
		}

		.section-six {
			.secondCol {
				.holder {
					flex-direction: column;

					.leftside {
						min-width: 60%;
						max-width: 60%;
					}

					.rightside {
						.text-holder {
							margin-left: 0;
						}
					}
				}

				.rightside {
					margin-top: 2em;
				}
			}
		}
	}

	.resourcespage-holder {
		.section-one {
			.title-holder {
				margin-bottom: 0;

				.filled-text {
					font-size: 34px;
					margin-bottom: 0;

					&.second {
						margin-bottom: 0px;

						&::before {
							height: 62px;
						}
					}
				}
			}
		}

		.section-two {
			.secondCol {
				.holder {
					flex-direction: column;

					.leftside {
						min-width: 60%;
						max-width: 60%;
					}

					.rightside {
						.text-holder {
							margin-left: 0;
						}
					}
				}
			}
		}

		.posts-holder {
			.team-member-holder {
				max-width: 100%;
				margin-bottom: 140px;

				.member-info {
					.name {
						padding-top: 10px;
						font-size: 21px;
					}
				}

				.member-description {
					top: -20px;
					left: -20px;
					right: -20px;
					padding: 20px;

					.name {
						font-size: 21px;
						padding-top: 10px;
					}

					.description {
						padding-top: 15px;
						font-size: 15px;
					}
				}

				&:hover {
					.image-holder {
						.learn-more-holder {
							a {
								font-size: 18px;
							}
						}
					}
				}
			}
		}

		.section-three {
			.titles-holder {
				.minititle {
					margin-bottom: 30px;
				}

				.section-title {
					font-size: 44px;
				}
			}
		}

		.section-four {
			.titles-holder {
				.section-title {
					font-size: 44px;
				}

				.section-subtitle {
					font-size: 21px;
					padding: 0 15px;
				}
			}
		}
	}

	.reviewspage-holder {
		.section-one {
			.titles-holder {
				.filled-text {
					font-size: 34px;
					margin-bottom: 20px;

					&.second {
						margin-bottom: 0px;

						&::before {
							height: 62px;
						}
					}
				}

				.text-field {
					font-size: 21px;
				}
			}
		}

		.section-two {
			.titles-holder {
				flex-direction: column;
			}

			.reviews-boxes-holder {
				.first-block {
					.leftside {
						margin-bottom: 20px;

						.firstCol {
							.text-holder {
								padding: 60px 60px;
								max-width: 100%;
							}
						}

						.secondCol {
							.text-holder {
								// max-width: 100%;
								// margin-bottom: 20px;
							}
						}

						.thirdCol {
							.text-holder {
								// padding: 20px 40px;
							}
						}
					}

					.rightside {
						margin-left: 0;
					}
				}

				.second-block {
					.leftside {
						.thirdCol {
							.image {
								margin-bottom: 20px;
							}
						}
					}

					.rightside {
						margin-left: 0;

						.firstCol {
							.text-holder {
								padding: 60px;
								max-width: 100%;
							}
						}

						.secondCol {
							// margin-bottom: 20px;

							.text-holder {
								// padding: 20px 40px;
							}
						}

						.thirdCol {
							margin-top: 20px;
							.text-holder {
								max-width: 100%;
								margin-left: 0;
							}
						}
					}
				}

				.third-block {
					.block-holder {
						flex-direction: column;

						.text-holder {
							padding: 60px 60px;
							max-width: 100%;
							min-width: 100%;
							margin-bottom: 20px;
						}

						.image-holder {
							margin-left: 0;
						}
					}
				}
			}
		}

		.section-four {
			.titles-holder {
				.section-title {
					font-size: 44px;
				}

				.section-subtitle {
					font-size: 21px;
					margin-bottom: 30px;
				}
			}
		}
	}

	.blogpage-holder {
		.section-one {
			min-height: 500px;

			.section-background-image {
				min-height: 500px;
				max-height: 500px;
			}
		}

		.explore-more-topics {
			.team-member-holder {
				max-width: 220px;
				margin-bottom: 140px;

				.member-info {
					.name {
						padding-top: 10px;
						font-size: 21px;
					}
				}

				.member-description {
					top: -20px;
					left: -20px;
					right: -20px;
					padding: 20px;

					.name {
						font-size: 21px;
						padding-top: 10px;
					}

					.description {
						padding-top: 15px;
						font-size: 15px;
					}
				}

				&:hover {
					.image-holder {
						.learn-more-holder {
							a {
								font-size: 18px;
							}
						}
					}
				}
			}
		}

		.blog-content {
			.topside {
				.title-holder {
					h1 {
						font-size: 44px;
					}

					.subtitle {
						font-size: 21px;
					}
				}
			}
		}

		.section-nine {
			.titles-holder {
				.section-title {
					font-size: 44px;
				}

				.section-subtitle {
					font-size: 21px;
					margin-bottom: 30px;
				}
			}
		}
	}

	.contactpage-holder {
		.section-one {
			.title-holder {
				.filled-text {
					font-size: 34px;

					&.second {
						margin-bottom: 0px;

						&::before {
							height: 62px;
						}
					}
				}
			}
		}
	}

	.inventorypage1-holder {
		.section-two {
			.titles-holder {
				margin-bottom: 60px;

				.section-title {
					font-size: 44px;
				}
			}

			.boxes-holder {
				.col-lg-4 {
					margin-bottom: 40px;
					display: flex;
					justify-content: center;
					align-items: center;
					align-items: center;
				}
			}
		}

		.section-three {
			.titles-holder {
				.section-title {
					font-size: 44px;
				}
			}
		}
	}

	.inventorypage2-holder {
		.section-one {
			margin-bottom: 20px;

			.text-holder {
				.titles-holder {
					margin-bottom: 0;

					.filled-text {
						font-size: 34px;
						margin-bottom: 0;

						&.second {
							margin-bottom: 0px;

							&::before {
								height: 62px;
							}
						}
					}
				}
			}
		}

		.section-four {
			.titles-holder {
				.section-title {
					font-size: 44px;
				}

				.section-subtitle {
					font-size: 21px;
					padding: 0 15px;
				}
			}
		}
	}

	.inventorypage3-holder {
		.section-one {
			.text-holder {
				.titles-holder {
					margin-bottom: 20px;

					.filled-text {
						font-size: 34px;
						margin-bottom: 0;

						&.second {
							margin-bottom: 0px;

							&::before {
								height: 62px;
							}
						}
					}
				}

				.text-field {
					font-size: 18px;
				}
			}
		}

		.section-three {
			margin-bottom: 60px;

			.vertical-tabs-holder {
				margin-bottom: 0;

				.nav {
					flex-direction: row !important;
					display: flex;
					max-width: 100%;
					justify-content: space-between;
					border-left: 0;
					border-bottom: 3px solid #ffdeaf;

					.nav-link {
						margin: 0;
						padding: 10px 15px;

						&::after {
							height: 0;
						}

						&.active {
							&::after {
								border-bottom: 2px solid #fcb447;
								width: 100%;
								bottom: -2px;
								left: 0;
								top: unset;
								height: 0;
							}
						}
					}
				}

				.tab-content {
					padding-bottom: 30px;

					.tab-pane {
						padding: 0;

						.image-holder {
							flex-direction: column;

							.icons-holder {
								.holder {
									margin-bottom: 30px;
								}
							}
						}
					}
				}

				.holder {
					.specifications-holder {
						.tabs-holder {
							.nav-tabs {
								.nav-link {
									.name {
										font-size: 21px;
									}
								}
							}

							.tab-content {
								.tab-pane {
									.tab-body {
										font-size: 18px;
									}
								}
							}
						}
					}
				}
			}
		}

		.section-six {
			.inventory-items-holder {
				.item-holder {
					max-width: 240px;

					margin: 0 auto;
				}
			}
		}

		.section-four {
			.titles-holder {
				.section-title {
					font-size: 44px;
				}

				.section-subtitle {
					font-size: 21px;
					margin-bottom: 30px;
				}
			}
		}
	}

	footer {
		.footer-menu {
			ul {
				justify-content: space-between;

				li {
					margin: 0;
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.resourcespage-holder {
		.posts-holder {
			.team-member-holder {
				.image-holder img {
					height: auto;
				}
			}
		}
	}
}

@media (max-width: 580px) {
	.blogpage-holder {
		.section-one {
			min-height: 500px;

			.section-background-image {
				min-height: 500px;
				max-height: 500px;
			}
		}

		.explore-more-topics {
			.team-member-holder {
				max-width: 360px;
				margin-bottom: 100px;

				.member-description {
					padding: 20px 35px;
				}
			}
		}

		.blog-content {
			.topside {
				.title-holder {
					h1 {
						font-size: 44px;
					}

					.subtitle {
						font-size: 21px;
					}
				}
			}
		}
	}

	.resourcespage-holder {
		.posts-holder {
			.team-member-holder {
				max-width: 100%;
				margin-bottom: 50px;

				.member-description {
					padding: 20px 35px;
				}
			}
		}
		.section-two {
			.secondCol {
				.holder {
					.leftside {
						max-width: 100%;
						min-width: 100%;
					}
				}
			}
		}
	}

	.inventorypage3-holder {
		.section-three {
			.vertical-tabs-holder {
				.thumbnails-mini-images-col {
					order: 2;
					width: 100%;
					margin-bottom: 30px;
				}

				.product-content-col {
					width: 100%;
				}

				.tab-content {
					padding-top: 0;

					.tab-pane {
						.image-holder {
							flex-direction: row;
						}
					}
				}
			}
		}

		.breadcrumb {
			margin-top: 40px;
		}
	}
	.faqpage-holder {
		.section-six {
			.secondCol {
				.holder {
					.leftside {
						max-width: 100%;
						min-width: 100%;
					}
				}
			}
		}
	}
}

@media (min-width: 480px) {
	.homepage-holder {
		.section-one {
			.text-holder {
				h4 {
					br {
						display: none;
					}
				}
			}
		}

		.section-two {
			.titles-holder {
				.section-subtitle {
					br {
						display: none;
					}
				}
			}
		}
	}
}

@media (max-width: 480px) {
	.mobile-hr {
		margin: 60px 12px;
	}

	.mobile-only {
		display: block;
	}

	.desktop-only {
		display: none;
	}

	.homepage-holder {
		.section-one {
			margin-bottom: 0;
			min-height: 480px;

			.section-background-image-overlay {
				min-height: 500px;
				max-height: 500px;
			}

			video {
				height: 500px !important;
				object-fit: cover;
				background-position: center top;
			}

			.text-holder {
				//min-height: 460px;
				min-height: 430px;
				margin-top: 20px;
				align-items: flex-start;

				h1 {
					display: flex;
					flex-direction: column;
					text-align: left;
					line-height: 50px;
				}

				h4 {
					text-align: left;
					font-size: 18px;
					margin-bottom: 30px;
				}

				.hero-btn {
					margin-left: 0;
					min-width: unset;
					max-width: unset;
				}
			}
		}

		.section-two {
			.titles-holder {
				margin-bottom: 40px;

				.section-title {
					font-size: 32px;
					text-align: left;
				}

				.section-subtitle {
					text-align: left;
					font-size: 18px;
				}
			}

			.boxes-holder {
				.col-lg-4 {
					margin-bottom: 40px;
					display: flex;
					justify-content: flex-start;
					align-items: flex-start;
				}

				.button-holder {
					.section-btn {
						margin-left: 0;
					}
				}
			}
		}

		.section-two {
			.container {
				padding: 20px;
			}
		}

		.section-three {
			padding: 0 20px;

			.titles-holder {
				.minititle {
					font-size: 18px;
				}

				.section-title {
					font-size: 32px;
				}
			}

			.tabs-holder {
				.nav-tabs {
					.nav-link {
						.number {
							display: none;
						}

						.name {
							font-size: 15px;
						}

						&::after {
							height: 3px;
						}
					}
				}

				.tab-content {
					.tab-pane {
						.tab-body {
							text-align: justify;
						}
					}
				}
			}
		}

		.section-three-mobile {
			display: block;
			padding: 0 12px;
			margin: 80px 0;

			.titles-holder {
				margin-bottom: 30px;

				.minititle {
					font-size: 18px;
				}

				.section-title {
					font-size: 32px;
				}
			}

			.accordion {
				border-radius: 2px;
				margin-bottom: 50px;

				.accordion-item {
					button {
						padding-left: 10px;
						background-color: transparent;
						font-size: 22px;
						color: #1c1c1c;
						letter-spacing: 1.2px;

						&:focus {
							box-shadow: none;
							border: 0;
						}

						&::after {
							display: none;
						}
					}

					.accordion-body {
						padding-left: 10px;
						color: #1c1c1c;
					}
				}
			}

			.button-holder {
				.section-btn {
					background-color: #eda228;
					color: #fff;
					font-size: 25px;
					padding: 10px 25px;
					display: flex;
					justify-content: center;
					align-items: center;
					-webkit-transition: all 0.3s;
					-moz-transition: all 0.3s;
					transition: all 0.3s;
					font-weight: 400;
					min-width: 220px;
					max-width: 220px;

					&:hover {
						background: #f4b959;
					}
				}
			}
		}

		.section-four {
			.text-holder {
				h2 {
					font-size: 44px;
				}
			}
		}

		.section-four {
			min-height: fit-content;
			max-height: 293px;
		}

		.section-five {
			margin-bottom: 0;
			padding-top: 40px;

			.text-holder {
				text-align: left;

				.lead-text {
					margin-bottom: 40px;
					font-size: 38px;

					p {
						margin-top: 0;
					}
				}
			}
		}

		.section-six {
			.secondCol {
				.text-holder {
					.section-title {
						//font-size: 32px;
						font-size: 29px;
						letter-spacing: 1.2px;
					}

					.section-subtitle {
						font-size: 21px;
						text-align: left;
					}
				}
			}
		}

		.section-seven {
			padding: 50px 0 60px 0;

			.content-holder {
				.list-holder {
					.glove-title {
						font-size: 48px;
					}

					.list-item {
						font-size: 23px;
						letter-spacing: 1.2px;
						font-weight: normal;

						.zero {
							font-size: 23px;
						}
					}
				}
			}
		}

		.section-eight {
			padding: 70px 0;
			padding-bottom: 140px;

			.slider-holder {
				.firstrtl {
					.slider-for {
						.slide {
							.text-holder {
								margin: 220px 15px 0 15px;
								min-height: 100%;
								padding-bottom: 14px;

								.review-text {
									font-size: 18px;
									margin-bottom: 20px;
									text-align: left;

									&::before {
										top: -70px;
										left: 0;
									}
								}
							}
						}
					}
				}

				.secondrtl {
					margin: 15px;
					width: unset;

					.slider-nav {
						top: 0;
						left: 0;
						min-width: 100%;
						max-width: 100%;
						position: relative;
						padding: 0;
						min-height: 100px;

						.slide {
							.text-holder {
								p {
									text-align: left;
									padding-left: 10px;
									font-size: 24px;
								}
							}
						}
					}
				}
			}
		}

		.section-nine {
			padding: 70px 15px;

			.titles-holder {
				.section-title {
					font-size: 32px;
					text-align: left;
				}

				.section-subtitle {
					padding: 0;
					font-size: 18px;
					text-align: left;
				}
			}

			.button-holder {
				text-align: left;
			}
		}

		// .section-ten {
		//   padding: 0;
		//   padding-top: 80px;

		//   .text-holder {
		//     p {
		//       font-size: 18px;
		//       text-align: left;
		//     }
		//   }
		// }
	}

	.about-holder {
		.section-one {
			margin-bottom: 20px;
		}

		.section-two {
			.minititle {
				font-size: 18px;
			}

			.mobile-wrapper {
				display: block;
			}

			.title-holder {
				.filled-text {
					font-size: 21px;
					margin-left: 0;
					width: 100%;
					background-position: 0% top, 0% top;

					&.first {
						width: 85%;
						margin-left: 0;
					}

					&.second {
						&::before {
							height: 44px;
							width: 1775px;
						}
					}

					&.third {
						width: 70%;
						margin-left: 30px;
					}
				}

				&.title-desktop {
					display: none;
				}

				&.title-mobile {
					display: block;
				}
			}

			.description {
				font-size: 21px;
			}
		}

		.section-three {
			min-height: auto;

			.section-background-image {
				display: none;
			}

			.titles-holder {
				min-height: auto;
				max-height: auto;
				padding-top: 60px;

				.minititle {
					font-size: 18px;
				}

				.section-title {
					font-size: 32px;
				}

				.section-subtitle {
					font-size: 21px;
					text-align: left;
				}

				.section-btn {
					min-width: 175px;
					max-width: 175px;
				}
			}
		}

		.section-hr-line {
			margin: 60px 0;
		}

		.vertical-tabs-holder {
			text-align: left;

			.title-holder {
				.minititle {
					font-size: 18px;
				}

				.title {
					font-size: 32px;
				}
			}

			.mob-flex-class {
				display: flex;
				flex-direction: column;

				.nav {
					flex-direction: row !important;
					display: flex;
					min-width: 100%;
					justify-content: space-between;
					border-left: 0;

					.nav-link {
						font-size: 18px;
						padding: 15px 0px 5px 0;

						&::after {
							height: 0;
						}

						&.active {
							&::after {
								border-bottom: 2px solid #fcb447;
								width: 100%;
								bottom: 0;
								left: 0;
								top: unset;
							}
						}
					}
				}

				.tab-content {
					padding-top: 50px;
					padding-bottom: 60px;

					.tab-pane {
						padding: 0;

						p {
							text-align: left;
						}
					}
				}
			}
		}

		.vertical-tabs-holder-mobile {
			display: block;
			padding: 0 12px;
			margin-bottom: 60px;

			.title-holder {
				margin-bottom: 30px;

				.minititle {
					font-size: 18px;
					text-transform: uppercase;
					letter-spacing: 2.2px;
					font-weight: 500;
					color: #6b6b6b;
					margin-bottom: 30px;
				}

				.title {
					font-size: 32px;
				}
			}

			.accordion {
				border-radius: 2px;
				margin-bottom: 50px;

				.accordion-item {
					button {
						padding-left: 10px;
						background-color: transparent;
						font-size: 22px;
						color: #1c1c1c;
						letter-spacing: 1.2px;

						&:focus {
							box-shadow: none;
							border: 0;
						}

						&::after {
							display: none;
						}
					}

					.accordion-body {
						padding-left: 10px;
						color: #1c1c1c;
					}
				}
			}

			.button-holder {
				.section-btn {
					background-color: #eda228;
					color: #fff;
					font-size: 25px;
					padding: 10px 25px;
					display: flex;
					justify-content: center;
					align-items: center;
					-webkit-transition: all 0.3s;
					-moz-transition: all 0.3s;
					transition: all 0.3s;
					font-weight: 400;
					min-width: 160px;
					max-width: 160px;

					&:hover {
						background: #f4b959;
					}
				}
			}
		}

		.what-makes-us-different-tabs {
			text-align: left;

			.titles-holder {
				margin-bottom: 40px;
				padding-top: 40px;

				.minititle {
					margin-bottom: 30px;
					font-size: 18px;
				}

				.section-title {
					font-size: 32px;
				}
			}

			.tabs-holder {
				.nav-tabs {
					display: flex;
					justify-content: space-between;
					align-items: center;

					.nav-link {
						max-width: 33%;
						padding: 12px 0;
						min-width: 33%;

						.number {
							font-size: 18px;
							margin-bottom: 10px;
						}

						.name {
							font-size: 18px;
							margin-bottom: 0;
							padding: 0;
						}
					}
				}

				.tab-content {
					padding-top: 50px;
					padding-bottom: 60px;

					.tab-pane {
						.tab-body {
							font-size: 21px;
							text-align: left;
						}
					}
				}
			}
		}

		.what-makes-us-different-mobile {
			display: block;
			padding: 0 12px;
			margin-bottom: 60px;

			.title-holder {
				margin-bottom: 30px;

				.minititle {
					font-size: 18px;
					text-transform: uppercase;
					letter-spacing: 2.2px;
					font-weight: 500;
					color: #6b6b6b;
					margin-bottom: 30px;
				}

				.title {
					font-size: 32px;
				}
			}

			.accordion {
				border-radius: 2px;
				margin-bottom: 50px;

				.accordion-item {
					button {
						padding-left: 10px;
						background-color: transparent;
						font-size: 22px;
						color: #1c1c1c;
						letter-spacing: 1.2px;

						&:focus {
							box-shadow: none;
							border: 0;
						}

						&::after {
							display: none;
						}
					}

					.accordion-body {
						padding-left: 10px;
						color: #1c1c1c;
					}
				}
			}

			.button-holder {
				display: none;

				.section-btn {
					background-color: #eda228;
					color: #fff;
					font-size: 25px;
					padding: 10px 25px;
					display: flex;
					justify-content: center;
					align-items: center;
					-webkit-transition: all 0.3s;
					-moz-transition: all 0.3s;
					transition: all 0.3s;
					font-weight: 400;
					min-width: 220px;
					max-width: 220px;

					&:hover {
						background: #f4b959;
					}
				}
			}
		}

		.get-to-know-us {
			.title {
				font-size: 34px;
			}
		}

		.team-members {
			// display: none;

			.team-member-holder {
				margin: 0 auto 260px auto;
			}
		}

		.team-members-mobile {
			display: block;

			.tm-box {
				position: relative;
				z-index: 1;
				max-width: 380px;
				margin: 0 auto;
				min-height: 640px;

				.team-member-holder {
					padding: 40px;

					.member-info {
						padding-top: 30px;

						.name {
							color: #1c1c1c;
							font-size: 25px;
							letter-spacing: 1.2px;
							font-weight: 500;
						}

						.position {
							color: #6b6b6b;
							font-size: 15px;
							letter-spacing: 1.2px;
							font-weight: 500;
							text-transform: uppercase;
						}
					}
				}

				.member-description-holder {
					display: none;

					p {
						color: #1c1c1c;
						font-size: 18px;
						letter-spacing: 1.2px;
						font-weight: 400;
						padding-top: 30px;
					}
				}

				&.active {
					.team-member-holder {
						background: #fff;
						box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.13);
						padding: 40px;

						.member-info {
							.name {
								color: #eda228;
							}
						}
					}

					.member-description-holder {
						display: block;
					}
				}
			}
		}

		.section-six {
			.firstCol {
				.titles-holder {
					.section-title {
						font-size: 32px;
						text-align: left;
					}

					.section-subtitle {
						text-align: left;
					}
				}
			}
		}

		.section-seven {
			padding: 50px 12px;

			.titles-holder {
				.section-title {
					font-size: 32px;
					text-align: left;
				}

				.section-subtitle {
					text-align: left;
					padding: 0;
				}
			}

			.button-holder {
				text-align: left;
			}
		}

		.section-eight {
			padding: 0;
			padding-top: 80px;

			.text-holder {
				p {
					font-size: 18px;
					text-align: left;
				}
			}
		}
	}

	.faqpage-holder {
		.section-one {
			margin-top: 40px;
			margin-bottom: 0;

			.title-holder {
				padding-left: 0;

				.filled-text {
					font-size: 21px;
					margin-left: 0;
					letter-spacing: 0.2px;
					padding-left: 10px;
					padding-right: 10px;

					&.first {
						margin-left: 0;
					}

					&.second {
						&::before {
							height: 44px;
							width: 1675px;
						}
					}

					&.third {
						margin-left: 0;
					}
				}
			}
		}

		.hr-line-full {
			margin-top: 40px;
			margin-bottom: 50px;
		}

		.section-three {
			display: block;
			margin-bottom: 0;

			.title-holder {
				.minititle {
					font-size: 18px;
				}

				.title {
					font-size: 32px;
				}
			}

			.vertical-tabs-holder {
				margin-bottom: 0;

				.title {
					margin-bottom: 30px;
				}

				.nav {
					.nav-link {
						font-size: 18px;
						padding-top: 0;
						margin-bottom: 10px;

						&::after {
							height: 0;
						}
					}
				}

				.tab-content {
					.tab-pane {
						padding: 0;
					}
				}
			}

			.accordion {
				.accordion-item {
					.accordion-body {
						padding-left: 30px;
					}
				}
			}
		}

		.section-six {
			padding: 60px 0;

			.firstCol {
				.titles-holder {
					margin-bottom: 40px;

					.section-title {
						font-size: 32px;
						text-align: left;
					}

					.section-subtitle {
						text-align: left;
					}
				}
			}

			.secondCol {
				.holder {
					.rightside {
						.text-holder {
							.box-subititle {
								text-align: left;
							}
						}
					}
				}
			}
		}

		.section-five {
			padding: 0;
			padding-top: 80px;

			.text-holder {
				p {
					font-size: 18px;
					text-align: left;
				}
			}
		}

		.section-four {
			padding: 70px 12px;

			.titles-holder {
				.section-title {
					font-size: 32px;
					text-align: left;
				}

				.section-subtitle {
					padding: 0;
					text-align: left;
				}
			}

			.button-holder {
				text-align: left;
			}
		}
	}

	.resourcespage-holder {
		.section-one {
			margin-top: 70px;

			.title-holder {
				.filled-text {
					font-size: 21px;
					margin-left: 0;

					&.first {
						margin-left: 0;
					}

					&.second {
						&::before {
							height: 44px;
							width: 1675px;
						}
					}

					&.third {
						margin-left: 30px;
					}
				}
			}
		}

		.section-two {
			padding: 60px 0;

			.firstCol {
				.titles-holder {
					margin-bottom: 40px;

					.section-title {
						font-size: 32px;
					}

					.section-subtitle {
						font-size: 18px;
						text-align: left;
					}
				}
			}

			.secondCol {
				.holder {
					.rightside {
						.text-holder {
							.eebook-holder {
								.eebook {
									font-size: 18px;
								}
							}

							.box-title {
								font-size: 18px;
							}

							.button-holder {
								.section-btn {
									max-width: 210px;
								}
							}
						}
					}
				}
			}
		}

		.section-three {
			.titles-holder {
				.minititle {
					font-size: 18px;
				}

				.section-title {
					font-size: 32px;
				}
			}
		}

		.posts-holder {
			padding-top: 60px;
			margin-bottom: 0;

			.section-title {
				font-size: 28px;
			}
		}

		.section-four {
			padding: 70px 12px;

			.titles-holder {
				.section-title {
					font-size: 32px;
					text-align: left;
				}

				.section-subtitle {
					text-align: left;
					padding: 0;
				}
			}

			.button-holder {
				text-align: left;
			}
		}

		.section-five {
			padding: 0;
			padding-top: 80px;

			.text-holder {
				p {
					font-size: 18px;
					text-align: left;
				}
			}
		}
	}

	.reviewspage-holder {
		.section-one {
			margin-top: 40px;
			margin-bottom: 0;

			.titles-holder {
				margin-bottom: 30px;

				.filled-text {
					font-size: 21px;
					margin-left: 0;

					&.first {
						margin-left: 0;
					}

					&.second {
						&::before {
							height: 44px;
							width: 1675px;
						}
					}

					&.third {
						margin-left: 30px;
					}
				}

				.text-field {
					font-size: 18px;
					text-align: left;
				}
			}

			video {
				width: 100%;
			}
		}

		.section-two {
			.titles-holder {
				align-items: flex-start;
			}
		}

		.section-four {
			padding: 70px 12px;

			.titles-holder {
				.section-title {
					font-size: 32px;
					text-align: left;
				}

				.section-subtitle {
					padding: 0;
					text-align: left;
				}
			}

			.button-holder {
				text-align: left;
			}
		}

		.hr-line-full {
			margin: 50px 0;
		}

		.section-five {
			padding: 0;
			padding-top: 80px;

			.text-holder {
				p {
					font-size: 18px;
					text-align: left;
				}
			}
		}
	}

	.blogpage-holder {
		.section-one {
			min-height: 400px;

			.section-background-image {
				min-height: 400px;
				max-height: 400px;
			}
		}

		.explore-more-topics {
			padding-top: 60px;
			margin-bottom: 60px;

			.section-title {
				font-size: 28px;
				text-align: left;
			}

			.slick-slide {
				overflow: hidden;
				padding-bottom: 40px;
			}

			.slick-dots {
				margin-top: 0;
			}
		}

		.blog-content {
			.topside {
				.title-holder {
					h1 {
						font-size: 32px;
					}

					.subtitle {
						font-size: 18px;
					}

					.author-holder {
						.author-name {
							font-size: 16px;
							margin-right: 0;
						}

						.author-text-holder {
							flex-direction: column;
							align-items: flex-start;

							.author-name {
								font-size: 16px;
								margin-right: 0;
							}

							.readtime {
								font-size: 16px;
							}
						}
					}
				}
			}

			.leftside {
				.post-content {
					.content-title {
						font-size: 21px;
					}

					p {
						font-size: 15px;
					}

					ul {
						padding-left: 50px;

						li {
							font-size: 15px;
						}
					}
				}

				.share-this-article {
					p {
						font-size: 18px;
					}
				}
			}
		}

		.section-nine {
			padding: 0 12px 70px 12px;

			.titles-holder {
				.section-title {
					font-size: 32px;
					text-align: left;
				}

				.section-subtitle {
					text-align: left;
					padding: 0;
				}
			}

			.button-holder {
				text-align: left;
			}
		}

		// .section-ten {
		//   padding: 0;
		//   padding-top: 80px;

		//   .text-holder {
		//     p {
		//       font-size: 18px;
		//       text-align: left;
		//     }
		//   }
		// }
	}

	.contactpage-holder {
		.section-one {
			margin-top: 40px;
			margin-bottom: 0;

			.title-holder {
				margin-bottom: 60px;

				.filled-text {
					font-size: 21px;
					margin-left: 0;
					margin-bottom: 20px;

					&.first {
						margin-left: 0;
					}
				}

				.text-field {
					font-size: 18px;
					text-align: left;
				}

				.fileld-text-desktop-holder {
					display: none;
				}

				.filled-text-mobile-holder {
					display: block;

					.filled-text {
						padding-left: 10px;
						padding-right: 10px;
					}
				}
			}
		}

		.section-three {
			.contact-form-holder {
				.button-holder {
					.section-btn {
						max-width: unset;
						min-width: unset;
					}
				}
			}
		}

		.section-five {
			padding: 0;
			padding-top: 80px;

			.text-holder {
				p {
					font-size: 18px;
					text-align: left;
				}
			}
		}
	}

	.inventorypage1-holder {
		.section-one {
			margin: 100px 0;
			margin-bottom: 0;

			.text-holder {
				.container {
					padding: 0;
				}

				.titles-holder {
					p {
						font-size: 21px;
						text-align: left;
					}
				}
			}

			.button-holder {
				text-align: left;
				display: none;
			}

			video {
				width: 100%;
			}
		}

		.section-two {
			.titles-holder {
				margin-bottom: 40px;

				.section-title {
					font-size: 32px;
					text-align: left;
				}

				.section-subtitle {
					font-size: 21px;
				}
			}

			.boxes-holder {
				.col-lg-4 {
					margin-bottom: 40px;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				.button-holder {
					text-align: left;

					.section-btn {
						margin-left: 0;
					}
				}

				.box {
					.title-visible {
						position: absolute;
						top: 0;
						bottom: 0;
						background: #eda22833;
						/* opacity: .7; */
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 35px;
						font-weight: 500;
						color: #fff;
						letter-spacing: 2.2px;
					}

					&:hover {
						.hover-text {
							display: none;
						}
					}
				}
			}
		}

		.filter-new {
			display: none;
		}

		.filter-sort {
			.select {
				justify-content: flex-start;
				margin-top: 40px;
			}
		}

		.section-three {
			.titles-holder {
				.section-title {
					font-size: 32px;
					text-align: left;

					span {
						display: inline-block;
					}
				}
			}
		}

		.section-five {
			padding: 0;
			padding-top: 80px;

			.text-holder {
				p {
					font-size: 18px;
					text-align: left;
				}
			}
		}

		.nav-filter-mobile {
			display: block;
			margin: 60px 0;

			.shopby {
				display: block;
				font-size: 21px;
				color: #1c1c1c;
				font-weight: 600;
				margin-bottom: 0;
				padding: 15px 12px;
				border-bottom: 1px solid #ccc;

				&.show {
					border-bottom: 0;
				}
			}

			.accordion-item {
				border-bottom: 1px solid #ccc;
				padding: 0 12px;

				&:nth-last-child(1) {
					border-bottom: 1px solid #ccc;
				}
			}

			.accordion-button {
				display: block;
				font-size: 21px;
				color: #1c1c1c;
				font-weight: 400;
				margin-bottom: 1px;
				padding: 15px 15px 15px 0;
				border: 0;
				background: transparent;

				&:focus {
					border: 0;
					box-shadow: none;
					background-image: none;
					background-color: transparent;
				}

				&::after {
					display: none;
				}
			}

			.accordion-body {
				padding: 0;
			}

			.nav-dropdown-holder {
				.lead-ul {
					list-style: none;
					//border-left: 2px solid #1c1c1c;
					padding-left: 25px;

					.lead-li {
						position: relative;
						margin-left: 20px;

						.category-name {
							font-size: 20px;
							color: #1c1c1c;
							display: block;
							font-weight: 400;
							margin-bottom: 1px;
							padding: 15px 15px 15px 0;
						}

						.sublead-ul {
							display: none;
							list-style: none;

							.sublead-li {
								a {
									display: block;
									font-size: 17px;
									color: #1c1c1c;
									font-weight: 400;
									margin-bottom: 1px;
									padding: 15px;
								}
							}
						}

						&::before {
							content: "";
							background-image: url("/img/accordion-right-arrow.png");
							display: block;
							position: absolute;
							left: -35px;
							top: 24px;
							width: 11px;
							height: 15px;
							z-index: 999999;
							transition: transform 0.2s ease-in-out;
						}

						&.show {
							&::before {
								content: "";
								background-image: url("/img/accordion-bottom-arrow.png");
								display: block;
								position: absolute;
								left: -38px;
								top: 25px;
								width: 15px;
								height: 11px;
								z-index: 999999;
								transition: transform 0.2s ease-in-out;
							}

							.sublead-ul {
								display: block;
							}
						}
					}
				}
			}
		}
	}

	.inventorypage2-holder {
		.section-one {
			margin-bottom: 20px;
			min-height: 250px;
			max-height: 250px;

			.text-holder {
				min-height: 250px;
				max-height: 250px;

				.titles-holder {
					.filled-text {
						font-size: 21px;
						margin-left: 0;

						&.first {
							width: 290px;
							margin-left: 0;
							margin-bottom: 20px;
						}

						&.second {
							margin-left: 0;
							width: 250px;

							&::before {
								height: 44px;
								width: 1675px;
							}
						}

						&.third {
							margin-left: 30px;
						}
					}
				}
			}

			.section-background-image {
				min-height: 250px;
				max-height: 250px;
			}
		}

		.section-two {
			display: none;
		}

		.section-four {
			padding: 0 12px 70px 12px;

			.titles-holder {
				.section-title {
					font-size: 32px;
					text-align: left;
				}

				.section-subtitle {
					text-align: left;
					padding: 0;
				}
			}

			.button-holder {
				text-align: left;
			}
		}

		.section-five {
			padding: 0;
			padding-top: 80px;

			.text-holder {
				p {
					font-size: 18px;
					text-align: left;
				}
			}
		}

		.filters {
			justify-content: flex-start;
			margin-top: 30px;
		}

		.nav-filter-mobile {
			display: none;
			margin: 60px 0;

			.shopby {
				display: block;
				font-size: 25px;
				color: #1c1c1c;
				font-weight: 600;
				margin-bottom: 10px;
				padding: 0;
			}

			.accordion-button {
				display: block;
				font-size: 25px;
				color: #1c1c1c;
				font-weight: 400;
				margin-bottom: 1px;
				padding: 15px 15px 15px 0;
				border-bottom: 0;
				background: transparent;

				&:focus {
					border: 0;
					box-shadow: none;
					background-image: none;
					background-color: transparent;
				}

				&::after {
					display: none;
				}
			}

			.accordion-body {
				padding: 0;
			}

			.nav-dropdown-holder {
				.lead-ul {
					list-style: none;
					border-left: 2px solid #1c1c1c;
					padding-left: 30px;

					.lead-li {
						position: relative;

						.category-name {
							font-size: 20px;
							color: #1c1c1c;
							display: block;
							font-weight: 400;
							margin-bottom: 1px;
							padding: 15px 15px 15px 0;
						}

						.sublead-ul {
							display: none;
							list-style: none;

							.sublead-li {
								a {
									display: block;
									font-size: 17px;
									color: #1c1c1c;
									font-weight: 400;
									margin-bottom: 1px;
									padding: 15px;
								}
							}
						}

						&.show {
							&::before {
								content: "";
								display: block;
								position: absolute;
								left: -32px;
								top: 0;
								width: 3px;
								height: 50px;
								background: #eda228;
								z-index: 999999;
							}

							.sublead-ul {
								display: block;
							}
						}
					}
				}
			}
		}

		.section-three {
			.inventory-items-holder {
				.item-holder {
					margin-bottom: 25px;
				}
			}

			.loadmore {
				text-align: left;
				margin-left: 15px;
			}

			.description-holder {
				margin-bottom: 10px;
			}
		}
	}

	.inventorypage3-holder {
		.section-one {
			min-height: auto;
			max-height: 100%;
			margin-top: 20px;
			margin-bottom: 0;

			.section-background-image {
				display: none;
			}

			.text-holder {
				min-height: auto;
				max-height: 100%;

				.titles-holder {
					.filled-text {
						font-size: 21px;
						margin-left: 0;

						&.first {
							margin-left: 0;
						}

						&.second {
							&::before {
								height: 44px;
								width: 1675px;
							}
						}

						&.third {
							margin-left: 30px;
						}
					}
				}

				.text-field {
					display: none;
				}
			}
		}

		.section-four {
			.titles-holder {
				.section-title {
					font-size: 32px;
				}
			}
		}

		.section-three {
			.vertical-tabs-holder {
				.leftpill {
					.nav-link {
						max-width: 25%;
					}
				}

				.tab-content {
					padding-top: 10px;
					padding-bottom: 0;

					.tab-pane {
						.image-holder {
							img {
								max-width: 65%;
							}
						}

						.icons-holder {
							.holder {
								p {
									font-size: 12px;
								}
							}
						}
					}

					// .nav {
					// }
				}

				.holder {
					.product-title {
						font-size: 21px;
					}

					.description {
						line-height: 24px;
					}

					.specifications-holder {
						display: none;

						.tabs-holder {
							.nav-tabs {
								display: flex;
								justify-content: space-between;
								align-items: center;
								border-bottom: 0;

								.nav-link {
									max-width: 33%;
									padding: 12px 0;
									min-width: 33%;
									padding-right: 0;
									text-align: center;

									.number {
										font-size: 18px;
										margin-bottom: 10px;
									}

									.name {
										font-size: 18px;
										margin-bottom: 0;
										padding: 0;
									}
								}
							}

							.tab-content {
								padding-top: 50px;
								padding-bottom: 60px;

								.tab-pane {
									.tab-body {
										font-size: 18px;
									}
								}
							}
						}
					}

					.specifications-holder-mobile {
						display: block;

						.accordion {
							border-radius: 2px;

							.accordion-item {
								button {
									padding-left: 10px;
									background-color: transparent;
									font-size: 22px;
									color: #1c1c1c;
									letter-spacing: 1.2px;

									&:focus {
										box-shadow: none;
										border: 0;
									}

									&::after {
										display: none;
									}
								}

								.accordion-body {
									padding-left: 10px;
									color: #1c1c1c;
								}
							}
						}
					}

					.buttons-holder {
						.button-one {
							width: 100%;
							margin-right: 0;
							margin-top: 30px;

							a {
								min-width: 100%;
							}
						}
					}
				}

				.gallery-holder {
					flex-wrap: wrap;

					.mini-photos-gallery-box {
						order: 2;
						flex-direction: row;
						margin-right: 0;
						justify-content: space-between;
						width: 100%;
						margin-top: 15px;

						img {
							max-width: 80px;
						}
					}
				}
			}
		}

		.section-six {
			.inventory-items-holder {
				.title {
					text-align: left;
				}

				.title-desktop {
					display: none;
				}

				.title-mobile {
					display: block;
				}

				.item-holder {
					max-width: 250px;
				}

				.product-slider {
					.slick-list {
						padding: 0;
					}
				}
			}
		}

		.section-four {
			padding: 0 12px 70px 12px;

			.titles-holder {
				.section-title {
					font-size: 32px;
					text-align: left;
				}

				.section-subtitle {
					text-align: left;
					padding: 0;
				}
			}

			.button-holder {
				text-align: left;
			}
		}

		.section-five {
			padding: 0;
			padding-top: 80px;

			.text-holder {
				p {
					font-size: 18px;
					text-align: left;
				}
			}
		}

		.section-two {
			display: none;
		}

		.nav-filter-mobile {
			display: block;
			margin: 60px 0;

			.shopby {
				display: block;
				font-size: 25px;
				color: #1c1c1c;
				font-weight: 600;
				margin-bottom: 10px;
				padding: 0;
			}

			.accordion-button {
				display: block;
				font-size: 25px;
				color: #1c1c1c;
				font-weight: 400;
				margin-bottom: 1px;
				padding: 15px 15px 15px 0;
				border-bottom: 0;
				background: transparent;

				&:focus {
					border: 0;
					box-shadow: none;
					background-image: none;
					background-color: transparent;
				}

				&::after {
					display: none;
				}
			}

			.accordion-body {
				padding: 0;
			}

			.nav-dropdown-holder {
				.lead-ul {
					list-style: none;
					padding-left: 30px;

					.lead-li {
						position: relative;

						.category-name {
							font-size: 20px;
							color: #1c1c1c;
							display: block;
							font-weight: 400;
							margin-bottom: 1px;
							//padding: 15px 15px 15px 0;
							padding: 8px 10px 8px 0;
						}

						.sublead-ul {
							display: none;
							list-style: none;

							.sublead-li {
								a {
									display: block;
									font-size: 17px;
									color: #1c1c1c;
									font-weight: 400;
									margin-bottom: 1px;
									padding: 15px;
								}
							}
						}

						&.show {
							&::before {
								content: "";
								display: block;
								position: absolute;
								left: -32px;
								top: 0;
								width: 3px;
								height: 50px;
								background: #eda228;
								z-index: 999999;
							}

							.sublead-ul {
								display: block;
							}
						}
					}
				}
			}
		}
	}

	footer {
		.footer-menu {
			ul {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				padding: 0;
				margin-bottom: 30px;
				align-items: center;

				li {
					margin-bottom: 0.5rem;
				}
			}

			.footer-nav-hr-line {
				margin-top: 0;
			}
		}
	}
}

@media (max-width: 780px) {
	.about-holder .section-two .title-holder > p.filled-text {
		font-size: 3.878vw;
	}

	.footer-info {
		flex-direction: column;

		.leftside {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
}

@media (max-width: 580px) {
	.about-holder {
		.section-two {
			.title-holder {
				.filled-text {
					width: 100%;
					background-size: 100%;
					background-image: linear-gradient(to right, #fcb447 100%, #fff 100%),
						linear-gradient(to right, #fcb447 100%, #ffffff 100%);

					&.first {
						width: 70%;
					}

					&.second {
						width: 70%;
						margin-left: 0;
					}
					&.third {
						width: 100%;
						margin-left: 15px;

						&::before {
							height: 44px;
							width: 1775px;
						}
					}
				}
			}
		}
	}
	.about-holder .section-two .title-holder {
		padding-left: 0;
		padding-right: 0;
		margin-bottom: 30px;

		& > p.filled-text {
			font-size: 4vw;
			display: inline-block !important;
			width: auto !important;
			max-width: 100% !important;
			margin: 6px 0 !important;
			text-align: left !important;
			position: relative !important;
		}
	}
}

/* START NEW CSS resourcepage page */

.resource-download-icon {
	position: absolute;
	right: 5px;
}
.resource-download-icon img {
	height: 30px !important;
	width: 30px !important;
}

@media (max-width: 420px) {
	.resource-download-icon img {
		height: auto !important;
		width: 30px !important;
		padding-top: 11px !important;
	}
}

// .header-navigation-menu {
//   margin-left: auto;

//   .navbar-nav {
//     text-align: center;
//     width: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;

//     .nav-link {
//       font-size: 20px;
//       color: #cfcfce;
//       margin-right: 40px;
//       font-weight: 400;
//       letter-spacing: 1.2px;
//       padding: 0;

//       &:hover {
//         color: #fff;
//       }

//       &:nth-last-child(1) {
//         margin-right: 0;
//       }

//       &.join-us-nav-link {
//         background-color: #f29e0d;
//         color: #fff;
//         padding: 3px 15px;

//         &:hover {
//           background-color: #f4b959;
//         }
//       }
//     }
//   }
// }

// .header-navigation-menu-secondary {
//   .navbar-nav {
//     .nav-link {
//       color: #1c1c1c;

//       &:hover {
//         color: #eda228;
//       }

//       &.join-us-nav-link {
//         color: #fff;
//       }
//     }
//   }
// }

.accordion-body p {
	font-size: inherit !important;
	padding-bottom: 6px;
	font-weight: inherit !important;
	text-align: justify;
	letter-spacing: inherit !important;
}

.blw-post-h1 {
	color: #1c1c1c;
	font-weight: 500;
	font-size: 52px;
	letter-spacing: 2.2px;
	justify-content: center;
	display: flex;
}

.blw-post-bold {
	font-weight: 900;
}

.blw-post-padding {
	padding-left: 20px;
	padding-right: 20px;
}

.blw-post-paragraph {
	padding-left: 30px;
	padding-right: 80px;
	text-align: justify;
	padding-top: 10px;
}

.blw-post-month {
	font-style: italic;
}

.blw-post-monthContainer {
	padding: 8px;
}

.blw-post-li {
	padding-top: 5px;
	padding-bottom: 5px;
}

.blw-post-p {
	padding-top: 10px;
	padding-bottom: 10px;
}

.blw-post-backgroundText {
	background-color: yellow;
	width: 100px;
}

.client-namev2 {
	margin-left: 1rem;
}

.buttonSubmitrReCaptcha {
	position: relative !important;
}

.reCaptcha {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
	opacity: 0;
}

.row-box {
	justify-content: center;
}

.team-member-holder {
	margin-bottom: 0 !important;
}

.row-box-item {
	border: 2px transparent solid;
	margin-left: 1rem;
	margin-bottom: 1rem;
	width: max-content;
	transition: all 0.3s;

	&:hover {
		box-shadow: 0 0 16px 0 rgb(0 0 0 / 13%);
		transition: all 0.3s;
	}
}

.row-box-item-descript {
	padding: 1.5rem;
}

.row-box-item-title {
	color: #eda228;
	font-size: 25px;
	letter-spacing: 1.2px;
	font-weight: 500;
}

.row-box-item-text {
	color: #1c1c1c;
	font-size: 18px;
	letter-spacing: 1.2px;
	font-weight: 400;
	padding-top: 30px;
}

@media (max-width: 876px) {
	.footer-menu-container-link {
		display: flex;
		flex-direction: column;
	}

	.footer-nav-item {
		margin-top: 1rem;
	}
}

@media (max-width: 576px) {
	h2 {
		font-size: 26px !important;
	}
}
