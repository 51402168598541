.popup {
}

.CookieConsent {
	max-width: 500px;
	border-radius: 5px;
	padding: 20px 30px;
	background-color: white;
	position: absolute;
	top: 50%;
	left: 50%;
	right: auto;
	bottom: auto;
	transform: translate3d(-50%, -50%, 9999990px);
	width: 95%;
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
}

.overlayclass {
	position: absolute;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 2;
	overflow: hidden;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

#rcc-confirm-button {
	margin-top: 2rem;
	padding: 0.5rem 1rem;
	background-color: #fcb53b;
}
