.fb-image-grid {
	min-width: 480px;
}
.fb-image-grid .containerImg {
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}
.fb-image-grid .row {
	margin-right: -15px;
	margin-left: -15px;
}
.fb-image-grid .col-xs-4,
.fb-image-grid .col-md-4,
.fb-image-grid .col-xs-6,
.fb-image-grid .col-md-6,
.fb-image-grid .col-xs-12,
.fb-image-grid .col-md-12 {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}
.fb-image-grid .col-xs-4,
.fb-image-grid .col-xs-6,
.fb-image-grid .col-xs-12 {
	float: left;
}
.fb-image-grid .col-xs-12 {
	width: 100%;
}
.fb-image-grid .col-xs-6 {
	width: 50%;
}
@media (min-width: 992px) {
	.fb-image-grid .col-md-4,
	.fb-image-grid .col-md-6,
	.fb-image-grid .col-md-12 {
		float: left;
	}
	.fb-image-grid .col-md-12 {
		width: 100%;
	}
	.fb-image-grid .col-md-6 {
		width: 50%;
	}
	.fb-image-grid .col-md-4 {
		width: 33.33333333%;
	}
}
.fb-image-grid .containerImg:before,
.fb-image-grid .containerImg:after,
.fb-image-grid .row:before,
.fb-image-grid .row:after {
	display: table;
	content: " ";
}
.fb-image-grid .containerImg:after,
.fb-image-grid .row:after {
	clear: both;
}
.fb-image-grid .grid-containerImg {
	text-align: center;
	margin: auto;
	width: 100%;
	padding: 10px;
}
.fb-image-grid .containerImg {
	padding-left: 0;
	padding-right: 0;
	width: 100% !important;
}
.fb-image-grid .containerImg * {
	margin: 0;
	padding: 0;
	cursor: pointer;
}
.fb-image-grid .col-md-4 {
	width: 33.33333333%;
}
.fb-image-grid .border {
	border: 2px solid white !important;
	border-radius: 6px;
}
.fb-image-grid .background {
	background-size: cover !important;
	background-position: center !important;
	background-repeat: no-repeat !important;
}
.fb-image-grid .height-one {
	width: 100%;
	padding-top: 100%;
}
.fb-image-grid .height-two {
	width: 50%;
	padding-top: 50%;
}
.fb-image-grid .height-three {
	width: 33.3333%;
	padding-top: 33.3333%;
}
.fb-image-grid .cover {
	background-color: #222;
	opacity: 0.8;
	position: absolute;
	right: 0;
	top: 0;
	left: 0;
	bottom: 0;
	border-radius: 6px;
}
.fb-image-grid .cover-text {
	right: 0;
	left: 0;
	bottom: 0;
	color: white;
	font-size: 7%;
	position: absolute;
	top: 50%;
	-webkit-transform: translate(0%, -50%);
	-ms-transform: translate(0%, -50%);
	transform: translate(0%, -50%);
	text-align: center;
}
.fb-image-grid .cover-text > p {
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.fb-image-grid .slide {
	height: 0;
	bottom: 100%;
	transition: 0.5s ease;
	overflow: hidden;
	font-size: 3%;
}
.fb-image-grid .border:hover .slide {
	bottom: 0;
	height: auto;
}
.fb-image-grid .border:hover .animate-text {
	top: 62%;
}

.border {
	position: relative;
}

.infoBtn {
	position: absolute;
	top: 1.25rem;
	right: 1.25rem;
	padding: 5px;
	z-index: 1;

	width: 6%;
	height: 6%;
}

.infoBtn .tooltiptext {
	visibility: hidden;
	width: 10rem;
	background-color: rgb(243, 196, 65, 0.5);
	color: black;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	bottom: -450%;
	right: -90%;
	margin-left: -60px;
	opacity: 0;
	transition: opacity 0.3s;
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);

	display: flex;
	flex-direction: column;
	padding: 0.6rem;

	font-size: 0.7rem;
	font-weight: 500;

	opacity: 0;
}

.infoBtn:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}

.tooltipMessage {
	width: 12rem;
	font-size: 0.9rem;
}

.ril__caption {
	height: max-content;
	top: 10%;

	display: flex;
	justify-content: center;
	align-items: center;
}

.ril__captionContent {
	font-size: 2rem;
	text-align: center;

	margin: 0 auto;
	opacity: 0.7;
}

.infoBtn .tooltiptext-One {
	width: max-content;
	right: -5%;
	bottom: -130%;
}

.infoBtn-two {
	width: 12%;
	height: 12%;
}

.infoBtn .tooltiptext-two {
	right: 110%;
	bottom: -110%;
}

.infoBtn-three {
	width: 15%;
	height: 15%;
}

.infoBtn .tooltiptext-three {
	right: -50%;
	bottom: -450%;
	width: 600%;
}

@media (max-width: 510px) {
	.fb-image-grid {
		min-width: 300px;
	}

	.fb-image-grid .grid-containerImg {
		width: 70%;
	}

	.infoBtn {
		position: absolute;
		top: 5%;
		right: 5%;
		padding: 5px;
		z-index: 1;
		border-radius: 50%;
		width: 10%;
		height: 10%;
	}

	.infoBtn .tooltiptext-One {
		bottom: -600%;
		right: -10%;
		max-width: 900%;
		display: none;
	}

	.infoBtn .tooltiptext-two {
		bottom: -600%;
		right: 0;
		max-width: 690%;
		display: none;
	}

	.infoBtn-two {
		top: 0;
		right: 0;
		width: 1rem;
		height: 1rem;
	}

	.infoBtn .tooltiptext-three {
		display: none;
	}

	.infoBtn-three {
		top: 0;
		right: 0;
		width: 1rem;
		height: 1rem;
	}
}

@media (max-width: 350px) {
	.fb-image-grid {
		min-width: 250px;
	}

	.fb-image-grid .grid-containerImg {
		width: 70%;
	}

	.infoBtn {
		position: absolute;
		top: 0.5rem;
		right: 0.5rem;
		padding: 5px;
		z-index: 1;
		border-radius: 50%;
		width: 15%;
		height: 15%;
	}

	.infoBtn .tooltiptext-One {
		bottom: -500%;
		right: -3%;
		max-width: 600%;
	}

	.infoBtn-two {
		top: 0;
		right: 0;
		width: 1rem;
		height: 1rem;
	}

	.infoBtn .tooltiptext-two {
		bottom: -600%;
		right: 0;
		max-width: 690%;

		display: none;
	}

	.infoBtn .tooltiptext-three {
		display: none;
	}

	.infoBtn-three {
		top: 0;
		right: 0;
		width: 1rem;
		height: 1rem;
	}
}
