.filter-new {
    background-color: #31302f;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70px;
    margin-bottom: 40px;
}

.filter-new nav {
    text-align: center;

}

.filter-new nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filter-new nav ul li > ul {
    background-color: #fff;
    display: block;
    width: 320px;
    text-align: left;
    padding: 0;
    border: 1px solid #1c1c1c;
}

.filter-new .nav li a {
    display: block;
    font-size: 25px;
    color: #CFCFCE;
    font-weight: 400;
    margin-bottom: 1px;
    padding: 15px;
}

.filter-new .nav li.bolded a {

    color: #fff;
    padding-left: 0;
}

.filter-new .nav li a:hover {
    color: #fff;
}

.filter-new nav .nav li ul li {
    padding: 5px 10px;
}

.filter-new nav .nav li ul li:hover {
    border-left: 4px solid #EDA228;
    background-color: #f0f1f2;
}

.filter-new nav .nav li ul li:nth-last-child(1) {
    border-bottom: 0;
}

.filter-new nav .nav li ul li a {
    font-size: 20px;
    color: #1c1c1c;
}

.filter-new nav .nav li ul li a:hover {
    color: #1c1c1c;
}


.filter-new .nav li li a {
    margin-top: 1px;
}


.filter-new .nav > li {
    float: left;
    margin-right: 1px;
    position: relative;
}


.filter-new .nav li a:first-child:nth-last-child(2):before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    top: 50%;
    right: 5px;
}


/* submenu positioning*/
.filter-new .nav ul {
    position: absolute;
    z-index: 1;
    left: -99999em;
}

.filter-new .nav > li:hover > ul {
    left: auto;
    min-width: 100%;
}

.filter-new .nav > li li:hover > ul {
    left: 100%;
    top: -1px;
}

@media (max-width: 480px)  {


    .filter-new .nav  {
        flex-direction: column;
    }


    .filter-new .nav ul {
        position: relative;
        z-index: 1;
        left: -99999em;
    }


    .filter-new .nav > li:hover > ul {
        left: auto;
        min-width: 100%;
        position: relative;
        left: 0;
        right: 0;
    }

    .filter-new .nav > li li:hover > ul {
        left: 100%;
        top: -1px;
    }
    .filter-new nav ul li > ul {

        display: none;
    }

    .filter-new nav .nav li ul li ul {

        display: none;
    }

    .filter-new nav ul li.show > ul {

        display: block;
        position: absolute;
    }

    .filter-new nav .nav li ul li.show ul {

        display: block;
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        border: 0;
        border-left: 1px solid #1c1c1c;
    }

    .filter-new nav .nav li ul li:hover {

        background: transparent;
    }

    .filter-new nav .nav .leadCategory {

        height: 70px;
    }

    .filter-new nav .nav .leadCategory ul .subCategory a {

        font-size: 20px;
    }

    .filter-new nav .nav .leadCategory ul .subCategory ul .subSubCategory a {

        font-size: 17px;
    }

}