@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css");
@import "./app.scss";
@import "./helpers/animate.css";
@import "./helpers/filter-menu.css";
@import "./helpers/review-slider.scss";

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap");

@import "react-image-gallery/styles/css/image-gallery.css";
@import "./react-image-gallery.css";
@import "./react-toggle.css";
@import "./fb-image-grid.css";
@import "./404.scss";
@import "./Popup.scss";

html,
body {
	padding: 0;
	margin: 0;
	font-family: "Roboto", sans-serif;
	color: #000;
	line-height: 1.15;
}

html {
	scroll-behavior: smooth;
}

.content {
	min-height: 100vh;
	display: grid;
	grid-template-rows: auto 1fr auto;
}

.container {
	margin-right: auto;
	margin-left: auto;
	width: 100%;
	display: flex;
	flex-direction: column;
}

h2 {
	font-size: 2.5rem;
	font-weight: 600;
}

h3 {
	font-size: 2.1875rem;
	font-weight: 600;
}

h4 {
	font-size: 1.25rem;
	font-weight: 600;
}

input,
button,
textarea {
	border: none;
	outline: none;
}

p.text {
	color: #808080;
	line-height: 1.625rem;
}

@media (min-width: 576px) {
	.container {
		width: 576px;
		padding-right: unset;
		padding-left: unset;
	}
}

@media (min-width: 768px) {
	.container {
		width: 768px;
	}
}

@media (min-width: 992px) {
	.container {
		width: 960px;
	}
}

@media (min-width: 1200px) {
	.container {
		width: 1140px;
	}
}

@media (min-width: 1400px) {
	.container {
		width: 1320px;
	}
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

.grecaptcha-badge {
	/* visibility: hidden; */
	z-index: 200;
}

@media (max-width: 650px) {
	.grecaptcha-badge {
		visibility: hidden;
	}
}
